import './App.css';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from '_components/snackbar';
import Chapters from 'views/class/chapters';
import Subjects from 'views/class/subjects';
import AddClassQuestion from 'views/class/classQuestion/addClassQuestion';
import ClassQuestions from 'views/class/classQuestion/classQuestions';
import Syllabus from 'views/class/classSyllabus/syllabus';
import AddSyllabus from 'views/class/classSyllabus/addSyllabus';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
	AddClassSubQuestions,
	EditClassQuestions,
	EditClassSubQuestions,
} from 'views/class/classQuestion';
import { Suspense, useState } from 'react';
import { AlertDialogProvider } from '_components/alertDialog';
import { AddAttachment, Attachments } from 'views/class/attachment';
import EditSyllabus from 'views/class/classSyllabus/editSyllabus';
import Format from 'views/format/format';
import QuestionSet from 'views/class/questionSet';
import Login from 'views/auth/login';
import { AuthContext } from '_hooks/auth';
import ProtectedRoute from '_components/protected-route';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 0,
			staleTime: 1000 * 60 * 60 * 24,
		},
	},
});
function App() {
	const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') ?? '');

	return (
		<FluentProvider theme={teamsLightTheme}>
			<AuthContext.Provider
				value={{
					accessToken,
					setAccessToken: (accessToken) => {
						localStorage.setItem('accessToken', accessToken);
						setAccessToken(accessToken);
					},
				}}
			>
				<BrowserRouter>
					<QueryClientProvider client={queryClient}>
						<SnackbarProvider>
							<AlertDialogProvider>
								<Suspense fallback={'Loading...'}>
									<Routes>
										<Route
											element={<Login />}
											path='login'
										/>
										<Route
											element={
												<ProtectedRoute>
													<></>
												</ProtectedRoute>
											}
											path='/'
										/>
										<Route
											element={
												<ProtectedRoute>
													<Format />
												</ProtectedRoute>
											}
											path='format'
										/>
										<Route
											element={
												<ProtectedRoute>
													<Chapters />
												</ProtectedRoute>
											}
											path='class/chapters'
										/>
										<Route
											element={
												<ProtectedRoute>
													<QuestionSet />
												</ProtectedRoute>
											}
											path='class/questionSet'
										/>
										<Route
											element={
												<ProtectedRoute>
													<Subjects />
												</ProtectedRoute>
											}
											path='class/subjects'
										/>
										<Route
											element={
												<ProtectedRoute>
													<AddClassQuestion />
												</ProtectedRoute>
											}
											path='class/add/questions'
										/>
										<Route
											element={
												<ProtectedRoute>
													<AddClassSubQuestions />
												</ProtectedRoute>
											}
											path='class/add/sub-questions'
										/>
										<Route
											element={
												<ProtectedRoute>
													<EditClassQuestions />
												</ProtectedRoute>
											}
											path='class/edit/questions/:id'
										/>
										<Route
											element={
												<ProtectedRoute>
													<EditClassSubQuestions />
												</ProtectedRoute>
											}
											path='class/edit/sub-questions/:id'
										/>
										<Route
											element={
												<ProtectedRoute>
													<ClassQuestions />
												</ProtectedRoute>
											}
											path='class/questions'
										/>
										<Route
											element={
												<ProtectedRoute>
													<Syllabus />
												</ProtectedRoute>
											}
											path='class/syllabus'
										/>
										<Route
											element={
												<ProtectedRoute>
													<AddSyllabus />
												</ProtectedRoute>
											}
											path='class/add/syllabus'
										/>
										<Route
											element={
												<ProtectedRoute>
													<EditSyllabus />
												</ProtectedRoute>
											}
											path='class/edit/syllabus/:id'
										/>
										<Route
											element={
												<ProtectedRoute>
													<Attachments />
												</ProtectedRoute>
											}
											path='class/attachment'
										/>
										<Route
											element={
												<ProtectedRoute>
													<AddAttachment />
												</ProtectedRoute>
											}
											path='class/add/attachment'
										/>
									</Routes>
								</Suspense>
							</AlertDialogProvider>
						</SnackbarProvider>
					</QueryClientProvider>
				</BrowserRouter>
			</AuthContext.Provider>
		</FluentProvider>
	);
}

export default App;
