import { memo, useState } from 'react';
import { makeStyles, Title3, tokens } from '@fluentui/react-components';
import { SyllabusFields, useSaveSyllabusApi } from '_hooks/class/syllabus';
import Header from '_components/header';
import Button from '_components/button';
import { useSnackbar } from '_components/snackbar';
import { ApiError, Option } from '_types/common';
import SyllabusForm from './syllabusForm';
import { SaveIcon } from '_components/icons';
import { useCommonStyles } from '_utils/styles';
import { optionsToValues } from '_utils/common';

const useStyles = makeStyles({
	formWrapper: {
		display: 'flex',
		gap: tokens.spacingVerticalL,
		flexDirection: 'column',
	},
});

const intialFormData: SyllabusFields = {
	content: '',
	className: {} as Option,
	board: {} as Option,
	chapters: [],
	subject: {} as Option,
	coverImg: [],
	sessions: [],
};
const AddSyllabus = () => {
	const { showSnackbar } = useSnackbar();
	const classes = { ...useStyles(), ...useCommonStyles() };
	const [formData, setFormData] = useState<SyllabusFields>(intialFormData);

	const SaveSyllabusApi = useSaveSyllabusApi();

	const onChange = (fieldName: keyof SyllabusFields | string, value: any) => {
		setFormData({
			...formData,
			[fieldName]: value,
		});
	};

	const onResetClick = () => {
		setFormData(intialFormData);
	};

	const onSaveClick = () => {
		SaveSyllabusApi.mutateAsync({
			request: {
				content: formData.content,
				className: formData.className.value,
				board: formData.board.value,
				chapters: optionsToValues(formData.chapters),
				subject: formData.subject.value,
				coverImg: formData.coverImg,
				...(formData.sessions?.length !== 0 && {
					sessions: optionsToValues(formData.sessions ?? []),
				}),
			},
		})
			.then((res) => {
				onResetClick();
				showSnackbar({ message: res.data.message, intent: 'success' });
			})
			.catch((error: ApiError) => {
				showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
			});
	};

	return (
		<>
			<Header title={<Title3>Add Syllabus</Title3>} />
			<div className={classes.mainContent}>
				<SyllabusForm
					formData={formData}
					onChange={onChange}
				/>
			</div>
			<div className={classes.stickyFooter}>
				<div className={classes.footerWrapper}>
					<Button
						appearance='outline'
						onClick={onResetClick}
					>
						Reset
					</Button>
					<Button
						appearance='primary'
						icon={<SaveIcon />}
						isLoading={SaveSyllabusApi.isPending}
						onClick={onSaveClick}
					>
						Save
					</Button>
				</div>
			</div>
		</>
	);
};

export default memo(AddSyllabus);
