import { Field, Input, Title3 } from '@fluentui/react-components';
import Button from '_components/button';
import Header from '_components/header';
import InputField from '_components/inputField';
import { useSnackbar } from '_components/snackbar';
import { Auth, AuthContext, useLoginApi } from '_hooks/auth';
import { ApiError } from '_types/common';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Login: FC = () => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const { showSnackbar } = useSnackbar();
	const [formData, setFormData] = useState<Auth>({ email: '', password: '' });

	const LoginApiApi = useLoginApi();
	const onChange = (field: string, value: string) => {
		setFormData({ ...formData, [field]: value });
	};

	const onSaveClick = () => {
		LoginApiApi.mutateAsync({ request: formData })
			.then((res) => {
				authContext.setAccessToken(res.data.accessToken);
				navigate('/');
				showSnackbar({ message: 'Login Successfull...', intent: 'success' });
			})
			.catch((error: ApiError) => {
				showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
			});
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Header title={<Title3>KC Academy: Login</Title3>} />
			<div
				//	className={classes.mainContent}
				style={{ display: 'flex', flexDirection: 'column', width: `25%`, gap: '20px' }}
			>
				<InputField
					fieldName={'email'}
					value={formData.email}
					fieldProps={{
						size: 'medium',
						label: 'Email',
						placeholder: 'Email',
						required: true,
					}}
					onChange={onChange}
				/>
				<Field
					label={'Password'}
					size='medium'
					required
				>
					<Input
						placeholder={'Password'}
						size='medium'
						onChange={(e, v) => onChange?.('password', v.value)}
					/>
				</Field>
				<Button
					appearance='primary'
					onClick={onSaveClick}
					disabled={!formData.email && !formData.password}
					isLoading={LoginApiApi.isPending}
				>
					Login
				</Button>
			</div>
		</div>
	);
};
export default Login;
