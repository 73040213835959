import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiSuccessResponse } from '_types/common';
import axios from 'axios';

export interface FormatResponse {
	_id: string;
	format: string;
	description: string;
}

export interface AddFormatRequest {
	format: string;
	description: string;
}
export const useGetFormatsApi = () =>
	useQuery({
		queryKey: ['formulas'],
		queryFn: () =>
			axios<FormatResponse[]>({
				method: 'GET',
				url: `/api/formulas`,
			}),
	});

export const useAddFormatsApi = () =>
	useMutation({
		mutationFn: (info: { request: AddFormatRequest }) =>
			axios<ApiSuccessResponse>({
				method: 'POST',
				url: `/api/formulas`,
				data: info.request,
			}),
	});
