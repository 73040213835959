import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiSuccessResponse } from '_types/common';
import axios from 'axios';

export interface SubjectResponse {
	_id: string;
	name: string;
}

export interface AddSubjectRequest {
	name: string;
}
export const useGetSubjectsApi = () =>
	useQuery({
		queryKey: ['subject'],
		queryFn: () =>
			axios<SubjectResponse[]>({
				method: 'GET',
				url: `/api/class/subjects`,
			}),
	});

export const useAddSubjectApi = () =>
	useMutation({
		mutationFn: (info: { request: AddSubjectRequest }) =>
			axios<ApiSuccessResponse>({
				method: 'POST',
				url: `/api/class/subjects`,
				data: info.request,
			}),
	});

export const useUpdateSubjectsApi = () =>
	useMutation({
		mutationFn: (info: { request: AddSubjectRequest; id: string }) =>
			axios<ApiSuccessResponse>({
				method: 'PUT',
				url: `/api/class/subjects/${info.id}`,
				data: info.request,
			}),
	});
