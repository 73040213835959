import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useCommonStyles = makeStyles({
	mainContent: {
		display: 'flex',
		flex: 'inherit',
		height: 'inherit',
		flexDirection: 'column',
		...shorthands.padding(tokens.spacingVerticalS, tokens.spacingVerticalL),
		flexGrow: 1,
		flexBasis: `1px`,
		overflow: 'auto',
	},
	mainContentGap: { gap: tokens.spacingVerticalL },
	stickyFooter: {
		position: 'sticky',
		bottom: 0,
		backgroundColor: tokens.colorBrandBackground2,
		...shorthands.padding(`10px`),
		boxShadow: tokens.shadow64Brand,
		zIndex: 100,
	},
	footerWrapper: {
		justifyContent: 'end',
		...shorthands.gap(tokens.spacingVerticalL),
		display: 'flex',
	},
	spinner: {
		paddingTop: tokens.spacingVerticalL,
	},
	notFoundImage: {
		paddingTop: tokens.spacingVerticalL,
		textAlignLast: 'center',
	},
});
