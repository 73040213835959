/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/no-for-in-array */
/**
 * <p> This is Search Box component with filter key and value suggestions with pagination</p>
 * <p> This component also shows filters in chip. </p>
 *
 * @format
 * @public
 * @class
 * @author Aman Harde
 * @since 1.0.0
 * @param {Props} props
 */
import { Component, createRef, FC, Fragment, ReactNode } from 'react';

import moment from 'moment';
import {
	getKeySuggestions,
	getFilteredSuggestions,
	generateDateKeys,
	genearateSearchQuery,
	generateAppliedFilters,
	getDaysDifference,
	getKeySuggestionsByPattern,
	generateShowingResult,
	parseQueryString,
	genearateSaveFilterResults,
	SEPERATOR,
	isSeperatorPresent,
	isSeperatorMatch,
	DEFAULT_PAGE_REQUEST,
} from './utils';
import {
	AppliedFilter,
	DateValues,
	FilterOption,
	GenearateSearchQueryProps,
	PageRequest,
	SaveFilters,
	ShowingResults,
	Suggestion,
	SuggestionValues,
} from './intefaces';
import {
	URLSearchParamsInit,
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import {
	Button,
	Caption1,
	Caption1Strong,
	Caption1Stronger,
	Divider,
	Dropdown,
	Field,
	Input,
	InteractionTag,
	InteractionTagPrimary,
	InteractionTagSecondary,
	makeStyles,
	Menu,
	MenuItem,
	MenuList,
	MenuPopover,
	MenuTrigger,
	Option,
	SelectionEvents,
	Spinner,
	Subtitle1,
	TagGroup,
	tokens,
} from '@fluentui/react-components';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import {
	bundleIcon,
	ArrowSyncFilled,
	DeleteLinesRegular,
	DismissFilled,
	DeleteLinesFilled,
} from '@fluentui/react-icons';
import Pagination from './pagination';

export const DeleteIcon = bundleIcon(DeleteLinesFilled, DeleteLinesRegular);

const DEFAULT_PAGE_SIZE = [10, 20, 50, 100];

interface State {
	patternSuggestions: FilterOption[];
	filteredKeys: FilterOption[];
	filteredSuggestions: SuggestionValues[];
	appliedFilters: AppliedFilter[];
	date: DateValues;
	userInput: string;
	listKeyIndex: number;
	listValueIndex: number;
	selectedKey: FilterOption;
	isShowSaveFilterModal: boolean;
	filterTitle: string;
	isDataLoading: boolean;
	waitForSaveFilter: boolean;
	pageRequest: PageRequest;
	dateError: string;
}

export interface SmartSearchProps {
	/**
	 * keys to select for apply filter on FilterOption
	 */
	keys: FilterOption[];
	/**
	 * suggestions to display suggestion for apply filter with selected key
	 */
	suggestions: Suggestion[];
	/**
	 * allowPagination for enable panination
	 * Show Pagination to change page
	 */
	allowPagination?: boolean;
	/**
	 * totalPages for enable pagination display pagination and change pages
	 * It works when `allowPagination` is `true`
	 */
	totalPages?: number;
	/**
	 * collectionName for enable save filters funtionality
	 */
	collectionName?: string;
	/**
	 * onSaveClick funtion to invokes when click on save filters
	 * Works when `collectionName` pass
	 */
	onSaveClick?: (
		collectionName: string,
		filtersToSave: SaveFilters[],
		title: string
	) => Promise<any>;
	/**
	 * onReload funtion to reload filters callback
	 */
	onReload: (appliedFilters: AppliedFilter[], pageRequest?: PageRequest) => Promise<any> | void;
	/**
	 * onFilterChange funtion to apply filters callback
	 */
	onFilterChange: (
		appliedFilters: AppliedFilter[],
		pageRequest?: PageRequest
	) => Promise<any> | void;
	/**
	 * onSuggestionChange  callback funtions works when suggestionType is dynamic
	 */
	onSuggestionChange?: (selectedKey: FilterOption, value: string) => Promise<SuggestionValues[]>;
	/**
	 * onLoad funtion to invokes when component mount
	 */
	onLoad?: () => void;
	/**
	 * showingResults to show all numberOfElements and totalElemnts on current page
	 * It works when `allowPagination` is `true`
	 */
	showingResults?: ShowingResults;
	/**
	 * resultSize to get number of results on page
	 * Default values DEFAULT_PAGE_SIZE
	 * It works when `allowPagination` is `true`
	 */
	resultSize?: number[];
	/**
	 * isDataLoading to show skeletons or internal use
	 */
	isDataLoading?: boolean;
	icons?: {
		search?: ReactNode;
		refresh?: ReactNode;
		clearAll?: ReactNode;
		bookmark?: ReactNode;
	};
}
interface ExtraProps {
	navigate: ReturnType<typeof useNavigate>;
	location: ReturnType<typeof useLocation>;
	params: ReturnType<typeof useParams>;
	classes: ReturnType<typeof useStyles>;
	search: {
		searchParams: URLSearchParams;
		setSearchParams: (
			nextInit: URLSearchParamsInit,
			navigateOptions?: { replace?: boolean; state?: any } | undefined
		) => void;
	};
}
class SmartSearch_ extends Component<SmartSearchProps & ExtraProps, State> {
	private smartSearchTextFiledRef = createRef<HTMLInputElement>();

	constructor(props: SmartSearchProps & ExtraProps) {
		super(props);
		this.state = {
			patternSuggestions: [],
			filteredKeys: [],
			filteredSuggestions: [],
			appliedFilters: [],
			date: {},
			userInput: '',
			listKeyIndex: 0,
			listValueIndex: 0,
			selectedKey: {} as FilterOption,
			isShowSaveFilterModal: false,
			filterTitle: '',
			isDataLoading: false,
			waitForSaveFilter: false,
			pageRequest: DEFAULT_PAGE_REQUEST,
			dateError: '',
		};
	}

	focusSearchTextField() {
		this.smartSearchTextFiledRef.current!.focus();
	}

	handleSearchParams = (params: GenearateSearchQueryProps) => {
		this.props.search.setSearchParams(genearateSearchQuery(params));
	};

	async componentDidMount() {
		const { location, keys, suggestions } = this.props;
		const { pageRequest } = this.state;

		const searchObject: Record<string, string | string[]> = parseQueryString(
			location.search.split('?')[1]
		) as Record<string, string | string[]>;
		const filterObj = generateAppliedFilters({ searchObject, keys, suggestions, pageRequest });

		await this.setState({
			...this.state,
			appliedFilters: filterObj.appliedFilters,
			pageRequest: {
				...filterObj.pageRequest,
				page: filterObj.pageRequest.page,
			},
		});
		await this.onFiltersApply(filterObj.appliedFilters, filterObj.pageRequest);
		this.props.onLoad?.();
		this.focusSearchTextField();
	}

	async componentDidUpdate(prevProps: any, prevState: State) {
		const { location, keys, suggestions } = this.props;
		const { pageRequest } = this.state;

		const searchObject: Record<string, string | string[]> = parseQueryString(
			location.search.split('?')[1]
		) as Record<string, string | string[]>;

		if (prevProps.location.key !== location.key) {
			const filterObj = generateAppliedFilters({ searchObject, keys, suggestions, pageRequest });

			await this.setState({
				...this.state,
				appliedFilters: filterObj.appliedFilters,
				pageRequest: {
					...filterObj.pageRequest,
					page: filterObj.pageRequest.page,
				},
			});
			await this.onFiltersApply(filterObj.appliedFilters, filterObj.pageRequest);
			await this.handleClickAway();
		}
	}

	// On filter apply
	onFiltersApply = (appliedFilter: AppliedFilter[], pageRequest?: PageRequest) => {
		const onFilterChange = this.props.onFilterChange(appliedFilter, pageRequest);
		if (!onFilterChange) return;
		this.setState({
			...this.state,
			isDataLoading: true,
		});

		onFilterChange
			?.then(() => {
				this.setState({
					...this.state,
					isDataLoading: false,
				});
			})
			?.catch(() => {
				this.setState({
					...this.state,
					isDataLoading: false,
				});
			});
	};

	handleClickAway = () => {
		this.setState({
			listKeyIndex: 0,
			listValueIndex: 0,
			filteredKeys: [],
			filteredSuggestions: [],
			patternSuggestions: [],
			date: {},
			isShowSaveFilterModal: false,
			waitForSaveFilter: false,
			filterTitle: '',
			dateError: '',
		});
		//this.smartSearchTextFiledRef.current!.blur();
	};

	// on user click in text field
	onInputClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const { keys, suggestions } = this.props;
		const { selectedKey, userInput } = this.state;

		if (selectedKey.type === 'date' || selectedKey.type === 'date_range') {
			this.setState({
				...this.state,
			});
			return;
		}
		// show suggestions
		if (userInput?.length === 0) {
			this.setState({
				...this.state,
				filteredKeys: getKeySuggestions({ keys, userInput }),
				filteredSuggestions: [],
				listKeyIndex: 1,
			});
			this.focusSearchTextField();
			return;
		}

		if (selectedKey.type === 'suggestion') {
			await this.setState({
				...this.state,
				filteredSuggestions: getFilteredSuggestions({
					suggestions,
					userInput,
					userInputValue: userInput?.split(SEPERATOR)[1],
				}),
				patternSuggestions: [],
				listValueIndex: 1,
			});
			this.focusSearchTextField();
			return;
		}

		if (userInput?.length !== 0 && isSeperatorMatch(userInput)) return;

		const filteredKeys = getKeySuggestions({ keys, userInput });
		await this.setState({
			...this.state,
			patternSuggestions:
				filteredKeys.length === 0 ? getKeySuggestionsByPattern({ keys, userInput }) : [],
			filteredKeys,
			filteredSuggestions: [],
			userInput,
			listKeyIndex: 1,
		});
		this.focusSearchTextField();
		return;
	};

	// on user types in text field
	onInputChange = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { selectedKey } = this.state;
		const { keys, suggestions, onSuggestionChange } = this.props;

		const userInput = event.currentTarget.value;
		const userInputValue = userInput.split(SEPERATOR)[1];

		if (selectedKey.type === 'date' || selectedKey.type === 'date_range') {
			return;
		}

		if (userInput.length === 0) {
			this.setState({
				userInput,
			});
			this.handleClickAway();
			return;
		}

		if (selectedKey.type === 'suggestion') {
			// if user input key for suggestion
			if (selectedKey.suggestionType === 'dynamic') {
				onSuggestionChange !== undefined &&
					onSuggestionChange(selectedKey, userInputValue)
						.then((res: SuggestionValues[]) => {
							this.setState({
								...this.state,
								filteredSuggestions: res,
								patternSuggestions: [],
								userInput,
								listKeyIndex: 1,
							});
							this.focusSearchTextField();
						})
						.catch(() => {
							this.setState({
								...this.state,
								filteredSuggestions: [],
								patternSuggestions: [],
								userInput,
							});
							this.focusSearchTextField();
						});
			} else {
				const filteredSuggestions = getFilteredSuggestions({
					suggestions,
					userInput,
					userInputValue: userInputValue ?? userInput,
				});
				const filteredKeys =
					filteredSuggestions.length === 0 ? getKeySuggestions({ keys, userInput }) : [];
				const patternSuggestions =
					!userInputValue && filteredKeys.length === 0
						? getKeySuggestionsByPattern({ keys, userInput })
						: [];
				await this.setState({
					...this.state,
					filteredSuggestions,
					patternSuggestions,
					filteredKeys: patternSuggestions.length === 0 ? filteredKeys : [],
					userInput: event.currentTarget.value,

					listKeyIndex: 1,
				});
				this.focusSearchTextField();
			}
			return;
		}
		const filteredKeys = getKeySuggestions({ keys, userInput });
		if (filteredKeys.length === 0 && !isSeperatorMatch(userInput)) {
			await this.setState({
				...this.state,
				patternSuggestions: getKeySuggestionsByPattern({ keys, userInput }),
				filteredKeys: [],
				filteredSuggestions: [],
				userInput: event.currentTarget.value,

				listKeyIndex: 1,
			});
			this.focusSearchTextField();
		} else {
			await this.setState({
				...this.state,
				filteredKeys,
				filteredSuggestions: [],
				patternSuggestions: [],
				userInput: event.currentTarget.value,
				listKeyIndex: 1,
			});
			this.focusSearchTextField();
		}
	};

	// on user select key using mouse click
	onKeySelection =
		(key: FilterOption) => async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			const { suggestions, onSuggestionChange } = this.props;
			const userInput = key.label + SEPERATOR;
			const userInputValue = '';

			const selectedKey: FilterOption = {
				label: key.label,
				value: key.value,
				type: key.type,
				maxDays: key.maxDays,
				suggestionType: key.suggestionType,
				pattern: key.pattern,
			};

			await this.setState({
				selectedKey,
			});
			this.focusSearchTextField();
			switch (key.type) {
				case 'suggestion':
					if (selectedKey.suggestionType === 'dynamic') {
						onSuggestionChange !== undefined &&
							onSuggestionChange(selectedKey, userInputValue)
								.then((res: SuggestionValues[]) => {
									this.setState({
										...this.state,
										filteredKeys: [],
										filteredSuggestions: res,
										userInput,
										listValueIndex: 1,
									});
								})
								.catch(() => {
									this.setState({
										...this.state,
										filteredKeys: [],
										filteredSuggestions: [],
										userInput,
									});
								});
					} else {
						await this.setState({
							...this.state,
							filteredKeys: [],
							filteredSuggestions: getFilteredSuggestions({
								suggestions,
								userInput,
								userInputValue,
							}),
							patternSuggestions: [],
							userInput,
							listValueIndex: 1,
						});
						this.focusSearchTextField();
					}

					break;

				case 'date_range':
				case 'date':
					await this.setState({
						...this.state,
						filteredKeys: [],
						filteredSuggestions: [],
						userInput,
						date: generateDateKeys({ suggestions, selectedKey }),
					});
					this.smartSearchTextFiledRef.current!.blur();
					break;

				default:
					await this.setState({
						...this.state,
						userInput,
						filteredKeys: [],
						filteredSuggestions: [],
					});

					this.handleClickAway();
					break;
			}
		};

	//on user click on suggestions using mouse
	onSuggestionClick =
		(value: SuggestionValues) => async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			const { appliedFilters, pageRequest, selectedKey } = this.state;

			const pagination = {
				...pageRequest,
				page: 1,
			};

			const filterObj: AppliedFilter = {
				key: selectedKey.value,
				label: selectedKey.label,
				value: '',
				selectedSuggestion: value,
				type: selectedKey.type,
				isValid: true,
			};

			await this.setState({
				...this.state,
				filteredSuggestions: [],
				userInput: '',
			});

			this.handleSearchParams({
				pageRequest: pagination,
				appliedFilters: appliedFilters.concat(filterObj),
				allowPagination: this.props.allowPagination,
			});

			await this.handleClickAway();
			this.focusSearchTextField();
		};

	//on user click on pattern suggestions using mouse
	onPatternSuggestionClick =
		(key: FilterOption) => async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			const { appliedFilters, pageRequest, userInput } = this.state;
			const pagination = {
				...pageRequest,
				page: 1,
			};

			const filterObj: AppliedFilter = {
				key: key.value,
				label: key.label,
				value: userInput,
				selectedSuggestion: null,
				type: key.type,
				isValid: true,
			};

			await this.setState({
				...this.state,
				filteredSuggestions: [],
				userInput: '',
			});

			this.handleSearchParams({
				pageRequest: pagination,
				appliedFilters: appliedFilters.concat(filterObj),
				allowPagination: this.props.allowPagination,
			});

			await this.handleClickAway();
			this.focusSearchTextField();
		};

	onTitleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		this.setState({
			...this.state,
			filterTitle: event.target.value,
		});
	};

	// on press  enter usng keyboard
	onEnterPress = async (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
	) => {
		const { suggestions, allowPagination, onSuggestionChange } = this.props;
		const {
			listKeyIndex,
			listValueIndex,
			userInput,
			appliedFilters,
			filteredKeys,
			filteredSuggestions,
			patternSuggestions,
			selectedKey,
			pageRequest,
		} = this.state;

		const pagination = {
			...pageRequest,
			page: 1,
		};

		const filterObj: AppliedFilter = {
			key: selectedKey.value,
			label: selectedKey.label,
			type: selectedKey.type,
			value: '',
			selectedSuggestion: null,
			isValid: true,
		};
		if (userInput !== '' && isSeperatorPresent(userInput) && userInput.split(SEPERATOR)[1] !== '') {
			filterObj.value = userInput.split(SEPERATOR)[1];

			this.setState({
				...this.state,
				userInput: '',
				selectedKey: {} as FilterOption,
			});

			this.handleSearchParams({
				pageRequest: pagination,
				appliedFilters: appliedFilters.concat(filterObj),
				allowPagination,
			});
			return;
		}
		if (filteredKeys.length !== 0 && listKeyIndex !== 0) {
			const _selectedKey = filteredKeys[listKeyIndex - 1];

			switch (_selectedKey.type) {
				case 'date':
				case 'date_range':
					await this.setState({
						userInput: _selectedKey.label + SEPERATOR,
						filteredKeys: [],
						filteredSuggestions: [],
						selectedKey: _selectedKey,
						date: generateDateKeys({ suggestions, selectedKey: _selectedKey }),
					});
					this.smartSearchTextFiledRef.current!.blur();
					break;

				case 'suggestion':
					if (_selectedKey.suggestionType === 'dynamic') {
						onSuggestionChange !== undefined &&
							onSuggestionChange(_selectedKey, '')
								.then((res: SuggestionValues[]) => {
									this.setState({
										...this.state,
										filteredSuggestions: res,
										filteredKeys: [],
										listKeyIndex: 0,
										listValueIndex: 1,
										userInput: _selectedKey.label + SEPERATOR,
										selectedKey: _selectedKey,
									});
								})
								.catch(() => {
									this.setState({
										...this.state,
										filteredSuggestions: [],
										filteredKeys: [],
										listKeyIndex: 0,
										userInput: _selectedKey.label + SEPERATOR,
										selectedKey: _selectedKey,
									});
								});
					} else {
						this.setState({
							filteredSuggestions: getFilteredSuggestions({
								suggestions,
								userInput: _selectedKey.label,
								userInputValue: '',
							}),
							filteredKeys: [],
							listKeyIndex: 0,
							listValueIndex: 1,
							userInput: _selectedKey.label + SEPERATOR,
							selectedKey: _selectedKey,
						});
					}
					break;

				default:
					this.setState({
						filteredSuggestions: getFilteredSuggestions({
							suggestions,
							userInput: _selectedKey.label,
							userInputValue: '',
						}),
						filteredKeys: [],
						listKeyIndex: 0,
						listValueIndex: 1,
						userInput: _selectedKey.label + SEPERATOR,
						selectedKey: _selectedKey,
					});
					break;
			}
			return;
		}
		if (filteredSuggestions.length !== 0 && listValueIndex !== 0) {
			filterObj.selectedSuggestion = filteredSuggestions[listValueIndex - 1];

			await this.setState({
				listValueIndex: 0,
				filteredSuggestions: [],
				userInput: '',
			});

			this.handleSearchParams({
				pageRequest: pagination,
				appliedFilters: appliedFilters.concat(filterObj),
				allowPagination,
			});

			await this.handleClickAway();
			return;
		}

		if (patternSuggestions.length !== 0 && listKeyIndex !== 0) {
			const _patternSelectedKey = patternSuggestions[listKeyIndex - 1];
			const filterObj: AppliedFilter = {
				key: _patternSelectedKey.value,
				label: _patternSelectedKey.label,
				value: userInput,
				selectedSuggestion: null,
				type: _patternSelectedKey.type,
				isValid: true,
			};

			await this.setState({
				...this.state,
				filteredSuggestions: [],
				userInput: '',
			});

			await this.handleSearchParams({
				pageRequest: pagination,
				appliedFilters: appliedFilters.concat(filterObj),
				allowPagination,
			});
			await this.handleClickAway();
			return;
		}
	};

	onArrowUpPress = async (event: any) => {
		const { listKeyIndex, listValueIndex, filteredKeys, filteredSuggestions, patternSuggestions } =
			this.state;

		if (filteredKeys.length !== 0) {
			if (listKeyIndex === 0) {
				await this.setState({ listKeyIndex: filteredKeys.length });
			} else {
				this.setState({ listKeyIndex: listKeyIndex - 1 });
			}
		} else if (filteredSuggestions.length !== 0) {
			if (listValueIndex === 0) {
				await this.setState({ listValueIndex: filteredSuggestions.length });
			} else {
				this.setState({ listValueIndex: listValueIndex - 1 });
			}
		} else if (patternSuggestions.length !== 0) {
			if (listKeyIndex === 0) {
				await this.setState({ listKeyIndex: patternSuggestions.length });
			} else {
				this.setState({ listKeyIndex: listKeyIndex - 1 });
			}
		}
	};

	onArrowDownPress = (event: any) => {
		const { listKeyIndex, listValueIndex, filteredKeys, filteredSuggestions, patternSuggestions } =
			this.state;

		if (filteredKeys.length !== 0) {
			if (listKeyIndex === filteredKeys.length) {
				this.setState({
					listKeyIndex: 1,
				});
			} else {
				this.setState({ listKeyIndex: listKeyIndex + 1 });
			}
		} else if (filteredSuggestions.length !== 0) {
			if (listValueIndex === filteredSuggestions.length) {
				this.setState({
					listValueIndex: 1,
				});
			} else {
				this.setState({ listValueIndex: listValueIndex + 1 });
			}
		} else if (patternSuggestions.length !== 0) {
			if (listKeyIndex === patternSuggestions.length) {
				this.setState({
					listKeyIndex: 1,
				});
			} else {
				this.setState({ listKeyIndex: listKeyIndex + 1 });
			}
		}
	};

	onSearchClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		this.onEnterPress(event).finally(() => {
			this.setState({
				...this.state,
				userInput: '',
				selectedKey: {} as FilterOption,
			});
		});
		return;
	};

	// on keyboard key press
	onKeyEvent = async (event: React.KeyboardEvent<HTMLDivElement>) => {
		switch (event.keyCode) {
			case 13:
				await this.onEnterPress(event);
				break;

			case 38:
				await this.onArrowUpPress(event);
				break;

			case 40:
				await this.onArrowDownPress(event);
				break;

			default:
				break;
		}
	};

	//on save filter click
	onSaveFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (!this.props.collectionName) return;
		const { appliedFilters } = this.state;

		this.props
			.onSaveClick?.(
				this.props.collectionName,
				genearateSaveFilterResults({ appliedFilters }),
				this.state.filterTitle
			)
			.then(() => {
				this.setState({
					...this.state,
					isShowSaveFilterModal: false,
				});
			})
			.catch((err: any) => {
				this.setState({
					...this.state,
					isShowSaveFilterModal: false,
				});
			});
	};
	// on chip delete
	handleChipDelete = (index: number) => (event: any) => {
		const appliedFilters = this.state.appliedFilters;
		const pageRequest = {
			page: 1,
			size: this.state.pageRequest.size,
		};

		appliedFilters.splice(index, 1);

		this.handleSearchParams({
			pageRequest,
			appliedFilters,
			allowPagination: this.props.allowPagination,
		});
	};

	// on clear all icon click
	onClearAllFilters = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const pageRequest = {
			page: 1,
			size: this.state.pageRequest.size,
		};

		this.handleSearchParams({
			pageRequest,
			appliedFilters: [],
			allowPagination: this.props.allowPagination,
		});
	};

	onSaveAppliedFilterClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		this.setState({
			...this.state,
			isShowSaveFilterModal: true,
		});
	};

	// on date change
	handleDateChange = (key: string, value?: Date | null) => {
		this.setState({
			...this.state,
			date: {
				...this.state.date,
				[key]: moment(value).format('YYYY-MM-DD'),
			},
			dateError: '',
		});
	};

	// on ok button click from date picker
	handleOKDatePicker = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const { suggestions } = this.props;
		const { appliedFilters, date, selectedKey, pageRequest } = this.state;

		const pagination = {
			...pageRequest,
			page: 1,
		};
		const filterObj: AppliedFilter = {
			key: selectedKey.value,
			label: selectedKey.label,
			type: selectedKey.type,
			value: '',
			selectedSuggestion: {} as SuggestionValues,
			isValid: true,
		};

		switch (selectedKey.type) {
			case 'date_range':
				if (selectedKey?.maxDays !== undefined) {
					if (getDaysDifference(date) < selectedKey?.maxDays) {
						for (const i in suggestions) {
							if (selectedKey.value === suggestions[i].ref) {
								for (const j in suggestions[i].values) {
									filterObj.selectedSuggestion = suggestions[i].values[j];
									filterObj.selectedSuggestion.valueMember = date;
								}
								break;
							}
						}
					} else {
						filterObj.isValid = false;
					}
				} else {
					for (const i in suggestions) {
						if (selectedKey.value === suggestions[i].ref) {
							for (const j in suggestions[i].values) {
								filterObj.selectedSuggestion = suggestions[i].values[j];
								filterObj.selectedSuggestion.valueMember = date;
							}
							break;
						}
					}
				}

				break;

			case 'date':
				filterObj.value = date[selectedKey.value] as any;
				break;

			default:
				break;
		}

		if (filterObj.isValid) {
			const appliedFilter = appliedFilters.concat(filterObj);

			this.setState({
				userInput: '',
				selectedKey: {} as FilterOption,
			});

			this.handleSearchParams({
				pageRequest: pagination,
				appliedFilters: appliedFilter,
				allowPagination: this.props.allowPagination,
			});
		} else {
			this.setState({
				dateError: `Date range should be not more than ${selectedKey?.maxDays ?? ''} days`,
			});
		}
	};

	// on cancle button click from date picker
	handleCancleDatePicker = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		this.setState({
			userInput: '',
			selectedKey: {} as FilterOption,
		});

		this.handleClickAway();
	};

	// handle pagination
	handlePaginationChange = (page: number) => {
		const { appliedFilters } = this.state;
		const pageRequest = {
			page,
			size: this.state.pageRequest.size,
		};

		this.handleSearchParams({
			pageRequest,
			appliedFilters,
			allowPagination: this.props.allowPagination,
		});
	};

	// on reload icon click
	onReloadButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const onReload = this.props.onReload(this.state.appliedFilters, this.state.pageRequest);
		if (!onReload) return;
		this.setState({
			...this.state,
			isDataLoading: true,
		});

		onReload
			?.then(() => {
				this.setState({
					...this.state,
					isDataLoading: false,
				});
			})
			?.catch((error: any) => {
				this.setState({
					...this.state,
					isDataLoading: false,
				});
			});
	};

	onResultSizeChange = (event: SelectionEvents, value?: string) => {
		const { appliedFilters } = this.state;
		const pageRequest = {
			page: 1,
			size: Number(value),
		};

		this.handleSearchParams({
			pageRequest,
			appliedFilters,
			allowPagination: this.props.allowPagination,
		});
	};

	render() {
		const {
			totalPages,
			showingResults,
			classes,
			allowPagination,
			resultSize = DEFAULT_PAGE_SIZE,
			icons,
		} = this.props;
		const {
			patternSuggestions,
			filteredKeys,
			filteredSuggestions,
			userInput,
			listValueIndex,
			listKeyIndex,
			appliedFilters,
			date,
			selectedKey,
			pageRequest,
			isShowSaveFilterModal,
			isDataLoading,
			waitForSaveFilter,
			dateError,
		} = this.state;

		const isLoading = this.props.isDataLoading || isDataLoading;
		const showPopover =
			patternSuggestions.length !== 0 ||
			filteredKeys.length ||
			(filteredSuggestions.length !== 0 && selectedKey.type === 'suggestion') ||
			selectedKey.type === 'date_range' ||
			selectedKey.type === 'date';
		let PatternSuggetionList: JSX.Element = <></>;
		if (patternSuggestions.length !== 0) {
			PatternSuggetionList = (
				<div>
					<div
					/* 	divider
								sx={{ paddingLeft: 1 }} */
					>
						<Subtitle1>{`Search With:`}</Subtitle1>
					</div>
					{patternSuggestions.map((key, index) => {
						return (
							<MenuItem
								/* 										sx={{
											background:
												index + 1 === listKeyIndex
													? theme.palette.action.focus
													: {
															'&:hover': {
																background: theme.palette.action.hover,
															},
														},
										}} */
								key={index + 1}
								onClick={this.onPatternSuggestionClick(key)}
							>
								{key.label}
							</MenuItem>
						);
					})}
				</div>
			);
		}

		let KeyList: JSX.Element = <></>;
		if (filteredKeys.length) {
			KeyList = (
				<MenuList>
					{filteredKeys.map((key, index) => {
						return (
							<MenuItem
								/* 	sx={{
												background:
													index + 1 === listKeyIndex
														? theme.palette.action.focus
														: {
																'&:hover': {
																	background: theme.palette.action.hover,
																},
															},
											}} */
								key={index + 1}
								onClick={this.onKeySelection(key)}
							>
								{key.label}
							</MenuItem>
						);
					})}
				</MenuList>
			);
		}

		let SuggestionList: JSX.Element = <></>;
		if (filteredSuggestions.length !== 0 && selectedKey.type === 'suggestion') {
			SuggestionList = (
				<MenuList>
					{filteredSuggestions.map((value, index) => {
						return (
							<MenuItem
								/* sx={{
												background:
													index + 1 === listValueIndex
														? theme.palette.action.focus
														: {
																'&:hover': {
																	background: theme.palette.action.hover,
																},
															},
											}} */
								key={index}
								onClick={this.onSuggestionClick(value)}
							>
								{value.displayMember}
							</MenuItem>
						);
					})}
				</MenuList>
			);
		}

		let dateSelector: JSX.Element = <></>;
		if (selectedKey.type === 'date_range' || selectedKey.type === 'date') {
			dateSelector = (
				<div className={`${classes.dateSelector} dateSelector`}>
					<div className={`${classes.datePicker} datePicker`}>
						{Object.keys(date).map((datePicker, index) => {
							return (
								<div key={index}>
									<Field
										label={
											selectedKey.type === 'date_range'
												? index === 0
													? 'From Date'
													: index === 1
														? 'To Date'
														: 'Select Date'
												: 'Select Date'
										}
									>
										<DatePicker
											defaultOpen={index === 0}
											value={date[datePicker] ? new Date(date[datePicker]) : new Date()}
											size='small'
											minDate={index === 1 ? new Date(Object.values(date)[0]) : undefined}
											maxDate={new Date()}
											isMonthPickerVisible={false}
											onSelectDate={(v) => this.handleDateChange(datePicker, v)}
										/>
									</Field>
								</div>
							);
						})}
					</div>

					{dateError !== '' && (
						<Caption1
							color='red'
							className={classes.error}
						>
							{dateError}
						</Caption1>
					)}
					<Divider />
					<div className={`${classes.dateHandler} dateHandler`}>
						<Button
							size='small'
							onClick={this.handleCancleDatePicker}
						>
							{'Cancel'}
						</Button>

						<Button
							size='small'
							appearance='primary'
							onClick={this.handleOKDatePicker}
						>
							{'Ok'}
						</Button>
					</div>
				</div>
			);
		}

		return (
			<div className={`${classes.smartSearch} smartSearch`}>
				<div className={`${classes.container} container`}>
					<div className={`${classes.searchBar} searchBar`}>
						<Menu
							open={Boolean(showPopover)}
							onOpenChange={(e, d) => {
								!(d.open || selectedKey.type === 'date_range' || selectedKey.type === 'date') &&
									this.handleClickAway();
							}}
						>
							<MenuTrigger disableButtonEnhancement>
								<Field
									size='medium'
									className={`${classes.field} field`}
								>
									<Input
										id='smart-search-input'
										color='primary'
										autoComplete='off'
										placeholder='Search...'
										ref={this.smartSearchTextFiledRef}
										onChange={this.onInputChange}
										onKeyDown={this.onKeyEvent}
										onClick={this.onInputClick}
										value={userInput}
										disabled={isLoading}
										contentAfter={
											<Button
												id='search'
												size='small'
												appearance='primary'
												onClick={this.onSearchClick}
												disabled={isLoading}
												title='Refresh'
											>
												{icons?.search ?? `Search`}
											</Button>
										}
									/>
								</Field>
							</MenuTrigger>
							<MenuPopover className={`${classes.popover} popover`}>
								{PatternSuggetionList}
								{KeyList}
								{SuggestionList}
								{dateSelector}
							</MenuPopover>
						</Menu>
						<div className='refreshButton'>
							{isLoading ? (
								<Spinner />
							) : (
								<Button
									size='medium'
									appearance='subtle'
									onClick={this.onReloadButtonClick}
									disabled={isLoading}
									title='Refresh'
									icon={(icons?.refresh as any) ?? <ArrowSyncFilled />}
								/>
							)}
						</div>
					</div>

					{allowPagination !== undefined && (
						<div className={`${classes.pagination} pagination`}>
							{showingResults !== undefined && (
								<div className={`${classes.pageResult} pageResult`}>
									{isLoading ? (
										<Spinner />
									) : (
										/* 		<Skeleton
											variant='rectangular'
											width={100}
											height={20}
											sx={{ borderRadius: 1 }}
										/> */
										<Subtitle1>
											{`${generateShowingResult(
												pageRequest.page,
												pageRequest.size,
												showingResults.totalFiltered,
												showingResults.numberOfElements
											)} ${appliedFilters.length > 0 ? `from ${showingResults.totalElements}` : ''}`}
										</Subtitle1>
									)}
								</div>
							)}
							{resultSize && (
								<div className={`${classes.pageSize} pageSize`}>
									<Dropdown
										id='result-size'
										className={`${classes.pageSizeDropdown} pageSizeDropdown`}
										size='small'
										value={pageRequest.size.toString()}
										selectedOptions={[pageRequest.size.toString()]}
										onOptionSelect={(e, v) => this.onResultSizeChange(e, v.optionValue)}
										disabled={isLoading}
									>
										{!resultSize.includes(pageRequest.size) && (
											<Option value={pageRequest.size.toString()}>
												{pageRequest.size.toString()}
											</Option>
										)}
										{resultSize.length > 0
											? resultSize.map((s, i) => (
													<Option
														key={i}
														value={s.toString()}
													>
														{s.toString()}
													</Option>
												))
											: DEFAULT_PAGE_SIZE.map((r, i) => (
													<Option
														key={i}
														value={r.toString()}
													>
														{r.toString()}
													</Option>
												))}
									</Dropdown>
								</div>
							)}
							{totalPages !== undefined && (
								<div className='pagination'>
									<Pagination
										onPageChange={this.handlePaginationChange}
										totalPageCount={Number(this.props.totalPages ?? 0)}
										siblingCount={0}
										currentPage={pageRequest.page}
										pageSize={pageRequest.size}
										disabled={isLoading}
									/>
								</div>
							)}
						</div>
					)}
				</div>

				{appliedFilters.length !== 0 && (
					<div className={`${classes.filters} filters`}>
						<TagGroup className={`${classes.tagGruop} tagGruop`}>
							{appliedFilters.map((chipValue, index) => (
								<InteractionTag
									value={chipValue.value}
									key={index}
									appearance='brand'
									size='small'
								>
									<InteractionTagPrimary
										hasSecondaryAction
										style={{
											...(!chipValue.isValid && {
												background: tokens.colorPaletteRedBackground2,
												color: tokens.colorStatusDangerBackground3Pressed,
											}),
										}}
									>
										<Caption1Stronger>{`${chipValue.label} : `}</Caption1Stronger>
										<Caption1Strong>
											{!chipValue.isValid ? 'Invalid Value' : chipValue.value}
										</Caption1Strong>
									</InteractionTagPrimary>
									<InteractionTagSecondary
										onClick={this.handleChipDelete(index)}
										style={{
											...(!chipValue.isValid && {
												background: tokens.colorPaletteRedBackground2,
											}),
										}}
									>
										<DismissFilled color={tokens.colorStatusDangerBackground3Pressed} />
									</InteractionTagSecondary>
								</InteractionTag>
							))}
							{appliedFilters.length !== 0 && (
								<Fragment>
									<Divider
										vertical
										className={classes.divider}
									/>

									<Button
										appearance='subtle'
										onClick={this.onClearAllFilters}
										size='small'
										title='Clear all filters'
										icon={
											(icons?.clearAll as any) ?? (
												<DeleteIcon color={tokens.colorStatusDangerBackground3Pressed} />
											)
										}
									/>
								</Fragment>
							)}
						</TagGroup>
					</div>
				)}
			</div>
		);
	}
}

export const SmartSearch = (props: SmartSearchProps) => {
	const location = useLocation();
	const params = useParams();
	const navigate = useNavigate();
	const classes = useStyles();
	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<SmartSearch_
			{...props}
			location={location}
			params={params}
			navigate={navigate}
			classes={classes}
			search={{
				searchParams,
				setSearchParams,
			}}
		/>
	);
};
export default SmartSearch;

const useStyles = makeStyles({
	smartSearch: { display: 'flex', flexFlow: 'column', gap: tokens.spacingHorizontalM },
	container: {
		display: 'flex',
		flex: 'auto',
		gap: tokens.spacingHorizontalM,
	},
	searchBar: { display: 'flex', flex: 'auto', gap: tokens.spacingHorizontalSNudge },
	field: {
		flex: 'auto',
	},
	popover: {
		maxWidth: `fit-content`,
	},
	pagination: {
		display: 'flex',
		gap: tokens.spacingHorizontalM,
	},
	pageResult: {
		alignSelf: 'center',
	},
	pageSize: {
		display: 'flex',
	},
	pageSizeDropdown: {
		minWidth: `64px`,
	},
	filters: {
		display: 'flow',
		alignSelf: 'flex-start',
		gap: tokens.spacingHorizontalSNudge,
	},
	tagGruop: {
		flexFlow: 'wrap',
		gap: tokens.spacingHorizontalSNudge,
	},
	divider: { flex: 0 },
	dateSelector: {
		display: 'flex',
		flexDirection: 'column',
		gap: tokens.spacingHorizontalS,
	},
	datePicker: {
		display: 'flex',
		gap: tokens.spacingHorizontalS,
	},
	error: {
		color: tokens.colorStatusDangerBackground3Pressed,
	},
	dateHandler: {
		display: 'flex',
		alignSelf: 'self-end',
		gap: tokens.spacingHorizontalS,
	},
});
