import { useMutation } from '@tanstack/react-query';
import { Attachment } from '_types/common';
import axios from 'axios';

export const useUploadFileApi = () =>
	useMutation({
		mutationFn: (request: { files: FileList | File[] }) => {
			const formData = new FormData();
			for (let i = 0; i < request.files.length; i++) {
				formData.append('files', request.files[i], request.files[i].name);
			}
			return axios<Attachment[]>({
				method: 'POST',
				url: `/api/files/upload`,
				data: formData,
			});
		},
	});
