import {
	Body1Stronger,
	TableCellActions,
	TableCellLayout,
	TableColumnDefinition,
	Title3,
	createTableColumn,
} from '@fluentui/react-components';
import { FC, memo, useState } from 'react';
import Header from '_components/header';
import Button from '_components/button';
import { AddIcon, CopyIcon } from '_components/icons';
import AddFormatDialog from './addFormatDialog';
import Table from '_components/table';
import { FormatResponse, useGetFormatsApi } from '_hooks/format';
import HtmlPreview from '_components/htmlPreview';
import katex from 'katex';
import { useCommonStyles } from '_utils/styles';

const Format: FC = () => {
	const classes = { ...useCommonStyles() };

	const GetFormatApi = useGetFormatsApi();

	const [handelAdd, setHandleAdd] = useState(false);

	const items: FormatResponse[] = GetFormatApi.data?.data ?? [];
	const columns: TableColumnDefinition<FormatResponse>[] = [
		createTableColumn({
			columnId: '_id',
			renderHeaderCell: () => {
				return <Body1Stronger>Id</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{item._id}</TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'format',
			renderHeaderCell: () => {
				return <Body1Stronger>Format / Formula</Body1Stronger>;
			},
			renderCell: (item) => {
				return (
					<TableCellLayout>
						{item.format}
						<TableCellActions>
							<Button
								icon={<CopyIcon />}
								appearance='subtle'
								title='Copy format / fromula'
								onClick={() => void navigator.clipboard.writeText(item.format)}
							/>
						</TableCellActions>
					</TableCellLayout>
				);
			},
		}),
		createTableColumn({
			columnId: 'preview',
			renderHeaderCell: () => {
				return <Body1Stronger>Preview</Body1Stronger>;
			},
			renderCell: (item) => {
				return (
					<TableCellLayout>
						{
							<HtmlPreview
								// eslint-disable-next-line import/no-named-as-default-member
								html={katex.renderToString(item.format, { throwOnError: false })}
							/>
						}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn({
			columnId: 'description',
			renderHeaderCell: () => {
				return <Body1Stronger>Description</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.description}</TableCellLayout>;
			},
		}),
	];
	return (
		<>
			<Header
				title={<Title3> Format / Formula</Title3>}
				content={
					<Button
						appearance='primary'
						icon={<AddIcon />}
						iconPosition='before'
						onClick={() => setHandleAdd(true)}
					>
						Add format / formula
					</Button>
				}
			/>
			<div className={classes.mainContent}>
				<Table
					items={items}
					columns={columns}
					isLoading={GetFormatApi.isLoading}
				/>
			</div>
			<AddFormatDialog
				onClose={() => setHandleAdd(false)}
				open={handelAdd}
				refetch={() => void GetFormatApi.refetch()}
			/>
		</>
	);
};

export default memo(Format);
