/* eslint-disable indent */
import {
	DataGrid,
	DataGridBody,
	DataGridCell,
	DataGridHeader,
	DataGridHeaderCell,
	DataGridProps,
	DataGridRow,
	Image,
	Spinner,
	makeStyles,
	tokens,
} from '@fluentui/react-components';
import { memo } from 'react';
import FileNotFoundImage from '_assets/images/file-not-found.png';
export type Props = DataGridProps & { isLoading?: boolean };

const useStyles = makeStyles({
	tableWrapper: {
		display: 'flex',
		flexGrow: 1,
		overflow: 'auto',
		flexDirection: 'column',

		'& .fui-DataGridHeader': {
			position: 'sticky',
			top: 0,
			zIndex: 1,
			backgroundColor: 'white',
		},
	},
	spinner: {
		paddingTop: tokens.spacingVerticalL,
	},
	notFoundImage: {
		paddingTop: tokens.spacingVerticalL,
		textAlignLast: 'center',
	},
});

const Table = (props: Props) => {
	const classes = useStyles();
	return (
		<div className={classes.tableWrapper}>
			<DataGrid {...props}>
				<DataGridHeader>
					<DataGridRow
						selectionCell={
							props.selectionMode === 'multiselect'
								? {
										checkboxIndicator: { 'aria-label': 'Select all rows' },
									}
								: undefined
						}
					>
						{({ renderHeaderCell }) => (
							<DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
						)}
					</DataGridRow>
				</DataGridHeader>
				{props.isLoading ? (
					<Spinner className={classes.spinner} />
				) : props.items?.length === 0 ? (
					<div className={classes.notFoundImage}>
						<Image
							src={FileNotFoundImage}
							width={`25%`}
						/>
					</div>
				) : (
					<DataGridBody>
						{({ item, rowId }) => (
							<DataGridRow
								key={rowId}
								selectionCell={
									props.selectionMode
										? props.selectionMode === 'multiselect'
											? {
													checkboxIndicator: { 'aria-label': 'Select row' },
												}
											: { radioIndicator: { 'aria-label': 'Select row' } }
										: undefined
								}
							>
								{({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
							</DataGridRow>
						)}
					</DataGridBody>
				)}
			</DataGrid>
		</div>
	);
};

export default memo(Table);
