import {
	Body1,
	Body1Strong,
	Body1Stronger,
	Checkbox,
	Dialog,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Divider,
	makeStyles,
	shorthands,
	tokens,
} from '@fluentui/react-components';
import { FC, memo } from 'react';

import { ClassQuestionResponse } from '_hooks/class/classQuestion';

import Button from './button';
import HtmlPreview from './htmlPreview';
import DetailsTable from './detailsTable';
import { useMapObjectIDsToOptionsFromAPI } from '_hooks/class/common';

interface Props {
	open: boolean;
	onClose: () => void;
	data?: ClassQuestionResponse;
}

const useStyles = makeStyles({
	contentWrapper: {
		display: 'flex',
		...shorthands.gap(tokens.spacingVerticalL),
		...shorthands.overflow('auto'),
		flexGrow: 1,
		flexBasis: `1px`,
	},
	dialogContent: { flexDirection: 'column' },
	dialog: {
		/* 		minWidth: `800px`, */
		maxWidth: `80vw`,
	},
	versionWrapper: {
		...shorthands.padding(`10px`),
	},
	queInfoContentWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: tokens.spacingVerticalL,
		...shorthands.padding(0, `10px`),
	},
	queContentWrapper: {
		...shorthands.padding(0, `10px`),
	},
	detailsTableRow: {
		verticalAlign: '-webkit-baseline-middle',
	},
	detailsTableName: { minWidth: `60px` },
	horizonatalDivider: {
		marginTop: tokens.spacingVerticalL,
		marginBottom: tokens.spacingVerticalL,
	},
});

const QuestionViewerDialog: FC<Props> = (props) => {
	const classes = useStyles();
	const options = useMapObjectIDsToOptionsFromAPI({
		questionSets: props.data?.questionSets,
	});

	return (
		<Dialog
			modalType='modal'
			open={props.open}
			onOpenChange={() => props.onClose()}
		>
			<DialogSurface className={classes.dialog}>
				<DialogBody>
					<DialogTitle>Question In Detail : {props.data?._id}</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						{/* <div className={classes.versionWrapper}> */}
						{props.data?.mapping.map((mapping, index) => (
							<div
								key={index}
								className={classes.queInfoContentWrapper}
							>
								<div>
									<Body1Stronger>Board : </Body1Stronger>
									<Body1Strong>{mapping?.board}</Body1Strong>
								</div>
								<Divider vertical />
								<div>
									<Body1Stronger>Class : </Body1Stronger>
									<Body1Strong>{mapping?.className}</Body1Strong>
								</div>
								<Divider vertical />
								<div>
									<Body1Stronger>Subject : </Body1Stronger>
									<Body1Strong>{mapping.subject}</Body1Strong>
								</div>
								<Divider vertical />
								<div>
									<Body1Stronger>Chapters : </Body1Stronger>
									<Body1Strong>{mapping.chapter}</Body1Strong>
								</div>
								{mapping?.sessions && (
									<>
										<Divider vertical />
										<div>
											<Body1Stronger>Seasons : </Body1Stronger>
											<Body1Strong>{mapping?.sessions?.join(', ')}</Body1Strong>
										</div>
									</>
								)}
								{mapping?.section && (
									<>
										<Divider vertical />
										<div>
											<Body1Stronger>Sections : </Body1Stronger>
											<Body1Strong>{mapping?.section}</Body1Strong>
										</div>
									</>
								)}
								{options?.questionSets && (
									<>
										<Divider vertical />
										<div>
											<Body1Stronger>Question set : </Body1Stronger>
											<Body1Strong>
												{options.questionSets?.map((s) => s.label).join(', ')}
											</Body1Strong>
										</div>
									</>
								)}
							</div>
						))}
						<Divider className={classes.horizonatalDivider} />
						<div className={classes.queContentWrapper}>
							<Body1Stronger>Question : </Body1Stronger>

							<HtmlPreview html={props.data?.question} />
						</div>

						<div className={classes.queContentWrapper}>
							<Body1Stronger>Answer : </Body1Stronger>

							<HtmlPreview html={props.data?.answer} />
						</div>
						<div className={classes.queContentWrapper}>
							<Body1Stronger>Video urls : </Body1Stronger>

							<Body1>{props.data?.videoUrls?.toString()}</Body1>
						</div>
						{props.data?.options && (
							<div className={classes.queContentWrapper}>
								<Body1Stronger>Options : </Body1Stronger>
								<DetailsTable
									classes={{ tr: classes.detailsTableRow, tdName: classes.detailsTableName }}
									data={Object.entries(props.data?.options).map((o) => ({
										name: (
											<>
												<Checkbox
													readOnly
													checked={props.data?.optionsAnswer?.includes(o[0])}
												/>
												<Body1Strong wrap={false}>{o[0]} : </Body1Strong>
											</>
										),
										value: <HtmlPreview html={o[1]} />,
										showWhen: Boolean(o[1]),
									}))}
								/>
							</div>
						)}
						<div className={classes.queContentWrapper}>
							<Body1Strong>Description : </Body1Strong>
							<HtmlPreview html={props.data?.description} />
						</div>

						<div className={classes.queContentWrapper}>
							<Body1Strong>Extra description : </Body1Strong>
							<HtmlPreview html={props.data?.extraDescription} />
						</div>
						{/* </div> */}
					</DialogContent>
					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button appearance='secondary'>Close</Button>
						</DialogTrigger>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
};

export default memo(QuestionViewerDialog);
