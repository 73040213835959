import { FC, memo, useMemo, useRef } from 'react';
import Quill from 'quill';
import ResizeModule from '@botom/quill-resize-module';
import katex from 'katex';
import axios from 'axios';
import { Attachment } from '_types/common';
import QuillEditor from './quillEditor';
import TableUp from 'quill-table-up';
import { useCaptureTripleClick } from '_utils/common';
window.katex = katex;

import 'quill/dist/quill.snow.css';
import 'katex/dist/katex.min.css';
import 'quill-table-up/index.css';

Quill.register('modules/resize', ResizeModule);
Quill.register({ 'modules/tableUp': TableUp }, true);

type Props = { onChange: (value: string) => void; value: string; placeholder?: string };

const Editor: FC<Props> = (props) => {
	const quillRef = useRef<QuillEditor | null>(null);
	const tablePopoverRef = useRef<HTMLDivElement | null>(null);

	const onTripleClick = (table: HTMLElement) => {
		handelTablePopover(table);
	};

	const { addListenerForTripleClick } = useCaptureTripleClick(onTripleClick);
	const attachTableListeners = () => {
		const tables = quillRef.current?.editor?.container.querySelectorAll('table');
		tables?.forEach((table) => {
			addListenerForTripleClick(table);
		});
	};

	const handelTablePopover = (table: HTMLElement) => {
		if (tablePopoverRef.current) {
			tablePopoverRef.current.style.display = 'flex';
			tablePopoverRef.current.style.top = `${table.getBoundingClientRect().top - tablePopoverRef.current.offsetHeight}px`;
			tablePopoverRef.current.style.left = `${table.getBoundingClientRect().left + table.getBoundingClientRect().width / 2 - tablePopoverRef.current.offsetHeight / 2}px`;

			const slider = tablePopoverRef.current.querySelector('input') as HTMLInputElement;
			const valueRender = tablePopoverRef.current.querySelector('span') as HTMLSpanElement;
			slider.value = table.style.width || '100';
			valueRender.innerHTML = `${table.style.width || '100'}%`;

			slider.oninput = (e: any) => {
				table.style.width = `${e.target.value as string}%`;
				slider.value = e.target.value;
				valueRender.innerHTML = `${e.target.value as string}%`;
			};

			const selectAlignment = tablePopoverRef.current.querySelector('select') as HTMLSelectElement;
			selectAlignment.oninput = (event: any) => {
				const align = event.target.value;
				if (table) {
					if (align === 'left') {
						table.style.float = 'left';
						table.style.marginLeft = '';
						table.style.marginRight = '';
					} else if (align === 'right') {
						table.style.float = 'right';
						table.style.marginLeft = '';
						table.style.marginRight = '';
					} else if (align === 'center') {
						table.style.float = '';
						table.style.marginLeft = 'auto';
						table.style.marginRight = 'auto';
					} else {
						table.style.float = '';
						table.style.marginLeft = '';
						table.style.marginRight = '';
					}
				}
			};
			const editor = quillRef.current?.getEditor();
			editor?.update('silent');
		}
	};

	const handleClickAwayTablePopover = (event: MouseEvent) => {
		if (tablePopoverRef.current && !tablePopoverRef.current.contains(event.target as Node)) {
			tablePopoverRef.current.style.display = 'none';
		}
	};

	const ImageHandler = () => {
		const editor = quillRef.current?.getEditor();
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.click();

		input.onchange = () => {
			const file: File = input?.files?.[0] as File;
			const formData = new FormData();
			formData.append('files', file, file.name);

			axios<Attachment[]>({
				method: 'POST',
				url: `/api/files/upload`,
				data: formData,
			})
				.then((response) => {
					const range = editor?.getSelection();
					editor?.insertEmbed(Number(range?.index), 'image', `${response.data[0].path}`);
				})
				.catch((error) => {
					alert(error?.response?.data?.message || 'Failed to upload file.');
				});
		};
	};

	const modules = useMemo(
		() => ({
			toolbar: {
				handlers: {
					image: ImageHandler,
				},
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	attachTableListeners();
	document.addEventListener('mousedown', handleClickAwayTablePopover);

	return (
		<div>
			<QuillEditor
				ref={quillRef}
				bounds={'.quill'}
				modules={{
					toolbar: {
						container: [
							['bold', 'italic', 'underline', 'strike'], // toggled buttons
							['blockquote', 'code-block'],
							['link', 'image', /*  'video', */ 'formula'],

							[{ header: 1 }, { header: 2 }], // custom button values
							[{ list: 'ordered' }, { list: 'bullet' } /*  { list: 'check' } */],
							[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
							[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
							[{ direction: 'rtl' }], // text direction

							/* [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown */
							[{ header: [1, 2, 3, 4, 5, 6, false] }],

							[{ color: [] }, { background: [] }], // dropdown with defaults from theme
							/* [{ font: [] }], */
							[{ align: [] }],
							[{ table: [] }],

							/* ['clean'], */ // remove formatting button
						],
						...modules.toolbar,
					},
					resize: {
						locale: {
							// change them depending on your language
							altTip: 'Hold down the alt key to zoom',
							floatLeft: 'Left',
							floatRight: 'Right',
							center: 'Center',
							restore: 'Restore',
						},
					},
					tableUp: {
						texts: {
							customBtnText: 'Select table size',
							confirmText: 'Confirm',
							cancelText: 'Cancel',
							rowText: 'Row',
							colText: 'Column',
							notPositiveNumberError: 'Please enter a positive integer',
						},
						selection: {
							tipText: false,
						},
					},
				}}
				placeholder={props.placeholder}
				theme='snow'
				value={props.value}
				onChange={(v, d, s: any) => {
					s !== 'api' && props.onChange?.(v);
				}}
			/>
			<div
				className='ql-table-popover'
				id='ql-table-popover'
				ref={tablePopoverRef}
			>
				<div className='ql-table-popover-div'>
					<label>Width:</label>
					<input
						type='range'
						min='10'
						max='100'
					/>
					{tablePopoverRef.current && (
						<span>{tablePopoverRef.current.querySelector('input')?.value}</span>
					)}
				</div>
				<div className='ql-table-popover-div'>
					<label>Align:</label>
					<select>
						<option value='none'>Select alignment</option>
						<option value='left'>Left</option>
						<option value='center'>Center</option>
						<option value='right'>Right</option>
					</select>
				</div>

				<button
					className='close-btn'
					onClick={() => {
						if (tablePopoverRef.current) {
							tablePopoverRef.current.style.display = 'none';
						}
					}}
				>
					Close
				</button>
			</div>

			<style>
				{`
				.ql-table-popover {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  z-index: 100;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  flex-flow: column;
  gap: 8px;
  width: 250px
}
.ql-table-popover-div {
  display: flex;
  align-items: self-end;
  gap: 8px;
}
.ql-table-popover .close-btn {
  align-self: self-end
}
				`}
			</style>
		</div>
	);
};

export default memo(Editor);
