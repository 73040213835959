import {
	ApiSuccessResponse,
	Attachment,
	Option,
	PageRequest,
	PaginationResponse,
} from '_types/common';
import { Options } from '_types/questionBankTypes';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { QuestionSetResponse } from './questionSet';

export type ClassQuestionMapping = {
	_id?: string;
	className: string;
	board: string;
	chapter: string;
	subject: string;
	sessions?: string[] | null;
	section?: string | null;
};

export type ClassQuestionMappingFields = {
	_id?: string;
	className: Option;
	board: Option;
	chapter: Option;
	subject: Option;
	sessions?: Option[];
	section?: Option;
};

export type ClassQuestionFields = {
	_id?: string;
	question: string;
	answer: string;
	'option.a': string;
	'option.b': string;
	'option.c': string;
	'option.d': string;
	'option.e': string;
	optionsAnswer?: string[];
	description?: string;
	extraDescription?: string;
	attachments?: Attachment[];
	videoUrls?: string[];
	questionSets: Option[];
	mapping: ClassQuestionMappingFields[];
};

export type ClassQuestionRequest = {
	_id?: string;
	question: string;
	answer: string;
	options?: Options;
	optionsAnswer?: string[];
	description?: string;
	extraDescription?: string;
	attachments?: Attachment[];
	videoUrls?: string[];
	questionSets?: string[];
	mapping: ClassQuestionMapping[];
};

export const useSaveQuestionApi = () =>
	useMutation({
		mutationFn: (info: { request: ClassQuestionRequest }) =>
			axios<ApiSuccessResponse>({
				method: 'POST',
				url: `/api/class/questions`,
				data: info.request,
			}),
	});

export type BaseClassQuestionResponse = {
	_id: string;
	question: string;
	answer: string;
	options?: Options;
	optionsAnswer?: string[];
	description?: string;
	extraDescription?: string;
	attachments?: Attachment[];
	videoUrls?: string[];
	questionSets: QuestionSetResponse[] & string[];
	questionInstruction?: string;
	mapping: ClassQuestionMapping[];
};
export type ClassQuestionResponse = BaseClassQuestionResponse & ClassSubQuestionsResponse;

export type GetClassQuestionsRequest = Partial<{
	question: string;
	className: string;
	board: string;
	chapters: string;
	subject?: string;
	sessions: string;
}> &
	PageRequest;

export const useGetClassQuestionsApi = (request?: GetClassQuestionsRequest) =>
	useQuery({
		queryKey: ['questions', request],
		queryFn: () =>
			axios<PaginationResponse<ClassQuestionResponse>>({
				method: 'GET',
				url: `/api/class/questions`,
				params: request,
			}),
		enabled: Boolean(request),
	});

export const useGetClassQuestionByIdApi = (id?: string) =>
	useQuery({
		queryKey: [id],
		queryFn: () =>
			axios<ClassQuestionResponse>({
				method: 'GET',
				url: `/api/class/questions/id/${id as string}`,
			}),
		enabled: Boolean(id),
	});

export const useDeleteQuestionApi = () =>
	useMutation({
		mutationFn: (info: { id: string }) =>
			axios<ApiSuccessResponse>({
				method: 'DELETE',
				url: `/api/class/questions`,
				data: info,
			}),
	});

export const useUpdateQuestionApi = () =>
	useMutation({
		mutationFn: (info: {
			request: Partial<Omit<ClassQuestionRequest, 'mapping'>> & { id: string };
		}) =>
			axios<ApiSuccessResponse>({
				method: 'PUT',
				url: `/api/class/questions`,
				data: info.request,
			}),
	});

export const useUpdateQuestionMappingApi = () =>
	useMutation({
		mutationFn: (info: { request: { mapping: ClassQuestionMapping[]; question: string } }) =>
			axios<ApiSuccessResponse>({
				method: 'PUT',
				url: `/api/class/questions/mapping`,
				data: info.request,
			}),
	});

export const useDeleteQuestionMappingApi = () =>
	useMutation({
		mutationFn: (info: { id: string; queId: string }) =>
			axios<ApiSuccessResponse>({
				method: 'DELETE',
				url: `/api/class/questions/mapping`,
				data: info,
			}),
	});

export type ClassSubQuestionsResponse = {
	question: string;
	subQuestions: BaseClassQuestionResponse[];
	mapping: Omit<ClassQuestionMappingFields, 'question'>[];
};

export type AddClassSubQuestionsRequest = {
	question: string;
	questionInstruction: string;
	subQuestions: Omit<ClassQuestionRequest, 'mapping'>[];
	mapping: Omit<ClassQuestionMapping, 'question'>[];
};

export type ClassSubQuestionsFields = {
	question: string;
	questionInstruction: string;
	subQuestions: Omit<ClassQuestionFields, 'mapping'>[];
	mapping: Omit<ClassQuestionMappingFields, 'question'>[];
};

export const useSaveSubQuestionsApi = () =>
	useMutation({
		mutationFn: (info: { request: AddClassSubQuestionsRequest }) =>
			axios<ApiSuccessResponse>({
				method: 'POST',
				url: `/api/class/questions/sub`,
				data: info.request,
			}),
	});

export const useUpdateSubQuestionsApi = () =>
	useMutation({
		mutationFn: (info: {
			request: Partial<Omit<AddClassSubQuestionsRequest, 'mapping'>> & { id: string };
		}) =>
			axios<ApiSuccessResponse>({
				method: 'PUT',
				url: `/api/class/questions/sub`,
				data: info.request,
			}),
	});

export const useDeleteSubQuestionApi = () =>
	useMutation({
		mutationFn: (info: { id: string; subQueId: string }) =>
			axios<ApiSuccessResponse>({
				method: 'DELETE',
				url: `/api/class/questions/sub`,
				data: info,
			}),
	});
