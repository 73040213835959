import {
	bundleIcon,
	AddFilled,
	AddRegular,
	EyeFilled,
	EyeRegular,
	EditRegular,
	EditFilled,
	DeleteRegular,
	DeleteFilled,
	DocumentSave20Filled,
	DocumentSave20Regular,
	CopyFilled,
	CopyRegular,
	Dismiss24Regular,
	Dismiss20Filled,
} from '@fluentui/react-icons';

export const AddIcon = bundleIcon(AddFilled, AddRegular);
export const EditIcon = bundleIcon(EditFilled, EditRegular);
export const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);
export const EyeIcon = bundleIcon(EyeFilled, EyeRegular);
export const SaveIcon = bundleIcon(DocumentSave20Filled, DocumentSave20Regular);
export const CopyIcon = bundleIcon(CopyFilled, CopyRegular);
export const Dismis = bundleIcon(Dismiss20Filled, Dismiss24Regular);
