import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { createContext } from 'react';

export type Auth = {
	email: string;
	password: string;
};

export type AuthContextType = {
	accessToken: string;
	setAccessToken: (accessToken: string) => void;
};

export const AuthContext = createContext<AuthContextType>({
	accessToken: '',
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setAccessToken: (accessToken: string) => undefined,
});

export type LoginResponse = {
	readonly expireIn: number;
	readonly accessToken: string;
};

export const useLoginApi = () =>
	useMutation({
		mutationFn: (info: { request: Auth }) =>
			axios<LoginResponse>({
				method: 'POST',
				url: `/app/login`,
				data: info.request,
			}),
	});

export const useLogoutApi = () =>
	useMutation({
		mutationFn: () =>
			axios<LoginResponse>({
				method: 'POST',
				url: `/app/logout`,
			}),
	});
