import {
	Dialog,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Field,
	Input,
	Subtitle2Stronger,
	Textarea,
	makeStyles,
	shorthands,
	tokens,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { FC, memo, useEffect, useState } from 'react';
import { useSnackbar } from '_components/snackbar';
import HtmlPreview from '_components/htmlPreview';
import katex from 'katex';
import { AddFormatRequest, useAddFormatsApi } from '_hooks/format';
import { ApiError } from '_types/common';
import Button from '_components/button';

const useStyles = makeStyles({
	fieldWrapper: {
		display: 'flex',
		flexDirection: 'column',
		...shorthands.gap(tokens.spacingVerticalL),
		...shorthands.padding(tokens.spacingVerticalL),
	},
	dialogAction: { paddingTop: tokens.spacingVerticalL },
});

interface AddFormatProps {
	open: boolean;
	onClose: () => void;
	refetch?: () => void;
}

export const AddFormat: FC<AddFormatProps> = (props) => {
	const classes = useStyles();
	const { showSnackbar } = useSnackbar();
	const [formData, setFormData] = useState<AddFormatRequest>({ description: '', format: '' });

	const AddFormatsApi = useAddFormatsApi();
	const onChange = (field: string, value: string) => {
		setFormData({ ...formData, [field]: value });
	};

	useEffect(() => {
		return () => {
			setFormData({ description: '', format: '' });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open]);

	const onSaveClick = () => {
		AddFormatsApi.mutateAsync({ request: formData })
			.then((res) => {
				props.onClose();
				props.refetch?.();
				showSnackbar({ message: res.data.message, intent: 'success' });
			})
			.catch((error: ApiError) => {
				showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
			});
	};

	return (
		<Dialog
			open={props.open}
			onOpenChange={props.onClose}
			modalType='modal'
		>
			<DialogSurface>
				<DialogBody>
					<DialogTitle
						action={
							<DialogTrigger action='close'>
								<Button
									appearance='subtle'
									aria-label='close'
									color='red'
									icon={<Dismiss24Regular />}
								/>
							</DialogTrigger>
						}
					>
						Add format / formula
					</DialogTitle>
					<DialogContent>
						<div className={classes.fieldWrapper}>
							<div>
								<Subtitle2Stronger>Preview</Subtitle2Stronger>
								<HtmlPreview
									// eslint-disable-next-line import/no-named-as-default-member
									html={katex.renderToString(formData.format, { throwOnError: false })}
									backgroundColor
									padding
								/>
							</div>
							<Field
								size='medium'
								label='Add format / formula'
							>
								<Textarea
									size='medium'
									resize='vertical'
									rows={6}
									name={`format`}
									value={formData.format}
									onChange={(event, data) => {
										onChange('format', data.value);
									}}
								/>
							</Field>
							<Field label='Description'>
								<Input onChange={(e, d) => onChange('description', d.value)} />
							</Field>
						</div>
					</DialogContent>
					<DialogActions className={classes.dialogAction}>
						<DialogTrigger disableButtonEnhancement>
							<Button
								appearance='secondary'
								disabled={AddFormatsApi.isPending}
							>
								Close
							</Button>
						</DialogTrigger>

						<Button
							appearance='primary'
							onClick={onSaveClick}
							isLoading={AddFormatsApi.isPending}
						>
							Save
						</Button>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
};

export default memo(AddFormat);
