/**
 * <p> This is Protected Route module to protect web page access with out login </p>
 *
 * @author Aman Harde
 * @since 1.0.0
 *
 */

import { makeStyles } from '@fluentui/react-components';
import { AuthContext } from '_hooks/auth';
import { FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import MainDrawer from 'views/drawer/mainDrawer';

interface ProtectedRouteProps {
	children: JSX.Element;
}

const useStyles = makeStyles({
	appWrapper: {
		display: 'flex',
	},
	routesWrapper: {
		flex: 'auto',
		overflow: 'auto',
		maxHeight: '100vh',
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		flexBasis: 'min-content',
		overflowY: 'hidden',
	},
});
const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }): JSX.Element => {
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	return authContext.accessToken ? (
		<div className={classes.appWrapper}>
			<MainDrawer />
			<div className={classes.routesWrapper}> {children}</div>
		</div>
	) : (
		<Navigate to={'/login'} />
	);
};

export default ProtectedRoute;
