import Axios, { AxiosError } from 'axios';

Axios.interceptors.request.use(
	(config) => {
		// bypass proxy directly call to server
		/* 	config.url = `${'http://localhost:5000'}${config.url as string}`;
		console.log('ci=ongi ', config); */
		const token = localStorage.getItem('accessToken');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

Axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error: AxiosError) => {
		if (error.response?.status === 401) {
			alert('Session expired. Please login again');
			window.open('/login', '_self');
		}
		return Promise.reject(error);
	}
);
