import {
	Dialog,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Field,
	Input,
	makeStyles,
	tokens,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { FC, memo, useEffect, useState } from 'react';
import { useSnackbar } from '_components/snackbar';
import { SubjectResponse, useAddSubjectApi, useUpdateSubjectsApi } from '_hooks/class/subjects';
import { ApiError } from '_types/common';
import Button from '_components/button';

interface AddSubjectDialogProps {
	open: SubjectResponse | boolean;
	onClose: () => void;
	refetch?: () => void;
}
const useStyles = makeStyles({
	dialogAction: { paddingTop: tokens.spacingVerticalL },
});
const AddSubjectDialog: FC<AddSubjectDialogProps> = (props) => {
	const classes = useStyles();
	const { showSnackbar } = useSnackbar();
	const AddSubjectApi = useAddSubjectApi();
	const UpdateSubjectsApi = useUpdateSubjectsApi();

	const [name, setName] = useState('');

	const isToUpdate = typeof props.open === 'boolean' ? false : true;
	const subject: SubjectResponse = props.open as SubjectResponse;

	useEffect(() => {
		if (isToUpdate) setName(subject.name);
		return () => setName('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open]);
	const onSaveClick = () => {
		AddSubjectApi.mutateAsync({ request: { name: name.trim() } })
			.then((res) => {
				props.onClose();
				props.refetch?.();
				showSnackbar({ message: res.data.message, intent: 'success' });
			})
			.catch((error: ApiError) => {
				showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
			});
	};

	const onUpdateClick = () => {
		UpdateSubjectsApi.mutateAsync({
			id: subject._id,
			request: {
				name: name.trim(),
			},
		})
			.then((res) => {
				props.refetch?.();
				props.onClose();
				showSnackbar({ message: res.data.message, intent: 'success' });
			})
			.catch((error: ApiError) => {
				showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
			});
	};

	return (
		<Dialog
			open={Boolean(props.open)}
			onOpenChange={props.onClose}
			modalType='modal'
		>
			<DialogSurface>
				<DialogBody>
					<DialogTitle
						action={
							<DialogTrigger action='close'>
								<Button
									appearance='subtle'
									aria-label='close'
									color='red'
									icon={<Dismiss24Regular />}
								/>
							</DialogTrigger>
						}
					>
						{isToUpdate ? `Update Subject: ${subject.name}` : 'Add Subject'}
					</DialogTitle>
					<DialogContent>
						<Field label='Subject name'>
							<Input
								value={name}
								onChange={(e, d) => setName(d.value)}
							/>
						</Field>
					</DialogContent>
					<DialogActions className={classes.dialogAction}>
						<DialogTrigger disableButtonEnhancement>
							<Button
								appearance='secondary'
								disabled={AddSubjectApi.isPending}
							>
								Close
							</Button>
						</DialogTrigger>

						{isToUpdate ? (
							<Button
								appearance='primary'
								onClick={onUpdateClick}
								isLoading={UpdateSubjectsApi.isPending}
								disabled={name.trim().length === 0}
							>
								Update
							</Button>
						) : (
							<Button
								appearance='primary'
								onClick={onSaveClick}
								isLoading={AddSubjectApi.isPending}
								disabled={name.trim().length === 0}
							>
								Save
							</Button>
						)}
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
};

export default memo(AddSubjectDialog);
