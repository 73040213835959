import { memo, useEffect, useState } from 'react';
import { Image, Spinner, Title3 } from '@fluentui/react-components';
import {
	SyllabusFields,
	SyllabusResponse,
	useGetSyllabusByIdApi,
	useUpdateSyllabusApi,
} from '_hooks/class/syllabus';
import Header from '_components/header';
import Button from '_components/button';
import { useSnackbar } from '_components/snackbar';
import { ApiError, Option } from '_types/common';
import SyllabusForm from './syllabusForm';
import { SaveIcon } from '_components/icons';
import { useParams } from 'react-router-dom';
import { useMapObjectIDsToOptionsFromAPI } from '_hooks/class/common';
import { useCommonStyles } from '_utils/styles';
import FileNotFoundImage from '_assets/images/file-not-found.png';
import { optionsToValues } from '_utils/common';

const EditSyllabus = () => {
	const { showSnackbar } = useSnackbar();
	const classes = { ...useCommonStyles() };
	const params = useParams();
	const GetSyllabusByIdApi = useGetSyllabusByIdApi(params.id);
	const UpdateSyllabusApi = useUpdateSyllabusApi();
	const options = useMapObjectIDsToOptionsFromAPI({
		chapters: GetSyllabusByIdApi.data?.data.chapters,
		subject: GetSyllabusByIdApi.data?.data.subject,
	});
	const intialFormData = (data?: SyllabusResponse): SyllabusFields => ({
		content: data?.content ?? '',
		className: { label: data?.className ?? '', value: data?.className ?? '' },
		board: { label: data?.board ?? '', value: data?.board ?? '' },
		chapters: options?.chapters as Option[],
		subject: options?.subject as Option,
		coverImg: data?.coverImg ?? [],
		sessions: data?.sessions?.map((s) => ({ label: s ?? '', value: s ?? '' })) ?? [],
	});

	const [formData, setFormData] = useState<SyllabusFields>(intialFormData());

	useEffect(() => {
		setFormData(intialFormData(GetSyllabusByIdApi.data?.data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [GetSyllabusByIdApi.dataUpdatedAt]);
	const onChange = (fieldName: keyof SyllabusFields | string, value: any) => {
		setFormData({
			...formData,
			[fieldName]: value,
		});
	};

	const onResetClick = () => {
		setFormData(intialFormData(GetSyllabusByIdApi.data?.data));
	};

	const onSaveClick = () => {
		UpdateSyllabusApi.mutateAsync({
			request: {
				id: params.id ?? '',
				content: formData.content,
				className: formData.className.value,
				board: formData.board.value,
				chapters: optionsToValues(formData.chapters),
				subject: formData.subject.value,
				coverImg: formData.coverImg,
				...(formData.sessions?.length !== 0 && {
					sessions: optionsToValues(formData.sessions ?? ([] as Option[])),
				}),
			},
		})
			.then((res) => {
				onResetClick();
				void GetSyllabusByIdApi.refetch();
				showSnackbar({ message: res.data.message, intent: 'success' });
			})
			.catch((error: ApiError) => {
				showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
			});
	};

	return (
		<>
			<Header title={<Title3>Edit Syllabus</Title3>} />
			<div className={classes.mainContent}>
				{GetSyllabusByIdApi.isLoading ? (
					<Spinner className={classes.spinner} />
				) : GetSyllabusByIdApi.isError ? (
					<div className={classes.notFoundImage}>
						<Image
							src={FileNotFoundImage}
							width={`25%`}
						/>
					</div>
				) : (
					<SyllabusForm
						formData={formData}
						onChange={onChange}
					/>
				)}
			</div>
			<div className={classes.stickyFooter}>
				<div className={classes.footerWrapper}>
					<Button
						appearance='outline'
						onClick={onResetClick}
					>
						Reset
					</Button>
					<Button
						appearance='primary'
						icon={<SaveIcon />}
						isLoading={UpdateSyllabusApi.isPending}
						onClick={onSaveClick}
					>
						Update
					</Button>
				</div>
			</div>
		</>
	);
};

export default memo(EditSyllabus);
