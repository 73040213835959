import { useGetChaptersApi } from './chapter';
import { ClassQuestionMapping, ClassQuestionMappingFields } from './classQuestion';
import { useGetQuestionSetApi } from './questionSet';
import { useGetSubjectsApi } from './subjects';

export const useMapObjectIDsToOptionsFromAPI = (data: {
	subject?: string;
	chapter?: string;
	subjects?: string[];
	chapters?: string[];
	questionSets?: string[];
}) => {
	const GetSubjectsApi = useGetSubjectsApi();
	const GetChaptersApi = useGetChaptersApi();
	const GetQuestionSetApi = useGetQuestionSetApi(!!data.questionSets);

	const chapter = GetChaptersApi.data?.data?.find((c) => data?.chapter?.includes(c._id));
	const subject = GetSubjectsApi.data?.data?.find((c) => data?.subject?.includes(c._id));

	return {
		...(data?.chapter && {
			chapter: { label: chapter?.name ?? '', value: chapter?._id ?? '' },
		}),
		...(data?.subject && {
			subject: { label: subject?.name ?? '', value: subject?._id ?? '' },
		}),
		...(data?.chapters && {
			chapters:
				GetChaptersApi.data?.data
					?.filter((c) => data?.chapters?.includes(c._id))
					?.map((s) => ({ label: s.name, value: s._id })) ?? [],
		}),
		...(data?.subjects && {
			subjects:
				GetSubjectsApi.data?.data
					?.filter((c) => data?.subjects?.includes(c._id))
					?.map((s) => ({ label: s.name, value: s._id })) ?? [],
		}),
		...(data?.questionSets && {
			questionSets:
				GetQuestionSetApi.data?.data
					?.filter((c) => data?.questionSets?.includes(c._id))
					?.map((s) => ({ label: s.name, value: s._id })) ?? [],
		}),
	};
};

export const mappingFieldsToRequest = (
	mapping: ClassQuestionMappingFields[]
): ClassQuestionMapping[] =>
	mapping.map((field) => ({
		_id: field._id,
		className: field.className.value,
		board: field.board.value,
		chapter: field.chapter.value,
		subject: field.subject.value,
		section: field.section?.value,
		sessions: field.sessions?.map((v) => v.value) ?? null,
	}));
