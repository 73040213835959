import { makeStyles, tokens } from '@fluentui/react-components';
import ClassFormHeader from '_components/classFormHeader';
import InputField, { InputFieldProps } from '_components/inputField';
import { SyllabusFields } from '_hooks/class/syllabus';
import { FC } from 'react';

const useStyles = makeStyles({
	formWrapper: {
		display: 'flex',
		gap: tokens.spacingVerticalL,
		flexDirection: 'column',
	},
});

type Props = {
	formData: SyllabusFields;
	onChange: InputFieldProps['onChange'];
};
export const SyllabusForm: FC<Props> = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.formWrapper}>
			<ClassFormHeader
				formData={props.formData}
				onChange={props.onChange}
			/>

			<InputField
				fieldName='content'
				fieldProps={{ label: 'Content', placeholder: 'Add content' }}
				fieldType='editor'
				value={props.formData?.content}
				onChange={props.onChange}
			/>

			<InputField
				fieldName='coverImg'
				fieldProps={{ label: 'Cover image ii', placeholder: 'Upload cover image' }}
				fieldType='upload'
				value={props.formData?.coverImg ?? []}
				onChange={props.onChange}
			/>
		</div>
	);
};
export default SyllabusForm;
