import { createContext, FC, ReactNode, useContext, useState } from 'react';
import Button, { ButtonProps } from './button';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogSurface,
	DialogBody,
} from '@fluentui/react-components';

type AlertDialogProps = {
	_key: number;
	variant?: 'error' | 'success';
	title: ReactNode;
	content?: ReactNode;
	onClose?: () => void;
	buttons?: ButtonProps[];
};

type AlertDialogContextType = {
	showAlertDialog: (props: Omit<AlertDialogProps, '_key'>) => void;
	hideAlertDialog: (_key?: number) => void;
};

const AlertDialogContext = createContext<AlertDialogContextType>({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
	showAlertDialog: (_props: Omit<AlertDialogProps, '_key'>) => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	hideAlertDialog: () => {},
});

export const useAlertDialog = (): AlertDialogContextType =>
	useContext<AlertDialogContextType>(AlertDialogContext);

export const AlertDialogProvider: FC<{ children: ReactNode }> = ({ children }): JSX.Element => {
	const [data, setData] = useState<AlertDialogProps>({} as AlertDialogProps);

	const showAlertDialog = (props: Omit<AlertDialogProps, '_key'>) => {
		setData({ _key: new Date().getTime(), ...props });
	};

	const hideAlertDialog = () => {
		setData({} as AlertDialogProps);
	};

	return (
		<AlertDialogContext.Provider value={{ showAlertDialog, hideAlertDialog }}>
			{children}
			<AlertDialog
				key={data._key}
				{...data}
				_key={data._key}
				onClose={hideAlertDialog}
			/>
		</AlertDialogContext.Provider>
	);
};

export const AlertDialog: FC<AlertDialogProps> = ({
	_key,
	title,
	content,
	buttons,
	onClose,
}): JSX.Element => {
	return (
		<Dialog
			modalType='modal'
			open={Boolean(_key)}
			key={_key}
			onOpenChange={onClose}
		>
			<DialogSurface /*  className={classes.dialog} */>
				<DialogBody>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent>{content}</DialogContent>

					{buttons && (
						<DialogActions>
							{buttons.map((v, i) => (
								<div key={i}>
									<Button {...v}>{v.children}</Button>
								</div>
							))}
						</DialogActions>
					)}
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
};
