import {
	ApiSuccessResponse,
	Attachment,
	Option,
	PageRequest,
	PaginationResponse,
} from '_types/common';
import { ChapterResponse } from './chapter';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { SubjectResponse } from './subjects';

export type SyllabusFields = {
	content: string;
	className: Option;
	board: Option;
	chapters: Option[];
	subject: Option;
	coverImg?: Attachment[];
	sessions?: Option[];
};

export type ClassSyllabusRequest = {
	content: string;
	className: string;
	board: string;
	chapters: string[];
	subject: string;
	coverImg?: Attachment[];
	sessions?: string[];
};

export const useSaveSyllabusApi = () =>
	useMutation({
		mutationFn: (info: { request: ClassSyllabusRequest }) =>
			axios<ApiSuccessResponse>({
				method: 'POST',
				url: `/api/class/syllabus`,
				data: info.request,
			}),
	});

export type SyllabusResponse = {
	_id: string;
	content: string;
	className: string;
	board: string;
	chapters: ChapterResponse[] & string[];
	subject: SubjectResponse & string;
	coverImg?: Attachment[];
	sessions?: string[];
};

export type GetSyllabusRequest = Partial<{
	classe: string;
	board: string;
	chapters?: string;
	subject?: string;
	sessions: string;
}> &
	PageRequest;

export const useGetSyllabusApi = (request?: GetSyllabusRequest) =>
	useQuery({
		queryKey: ['syllabus', request],
		queryFn: () =>
			axios<PaginationResponse<SyllabusResponse>>({
				method: 'GET',
				url: `/api/class/syllabus`,
				params: request,
			}),
		enabled: Boolean(request),
	});

export const useGetSyllabusByIdApi = (id?: string) =>
	useQuery({
		queryKey: [id],
		queryFn: () =>
			axios<SyllabusResponse>({
				method: 'GET',
				url: `/api/class/syllabus/${id as string}/get`,
			}),
		enabled: Boolean(id),
	});

export const useDeleteSyllabusApi = () =>
	useMutation({
		mutationFn: (info: { id: string }) =>
			axios<ApiSuccessResponse>({
				method: 'DELETE',
				url: `/api/class/syllabus/${info.id}/delete`,
			}),
	});

export const useUpdateSyllabusApi = () =>
	useMutation({
		mutationFn: (info: { request: Partial<ClassSyllabusRequest> & { id: string } }) =>
			axios<ApiSuccessResponse>({
				method: 'PUT',
				url: `/api/class/syllabus`,
				data: info.request,
			}),
	});
