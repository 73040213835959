/* eslint-disable @typescript-eslint/no-for-in-array */
/** @format */

import {
	FilteredSuggestionProps,
	keySuggestionProps,
	DateKeysProps,
	GenearateSearchQueryProps,
	GenerateAppliedFiltersProps,
	AppliedFilter,
	GenearateSaveFilterResultsProps,
	SuggestionValues,
	GenerateAppliedFilters,
	FilterOption,
	DateValues,
	PageRequest,
	SearchRequest,
	SaveFilter,
	SaveFilters,
} from './intefaces';
import moment from 'moment';

export const SEPERATOR_REGX = /: /g;
export const SEPERATOR = ': ';
export const DEFAULT_PAGE_REQUEST: PageRequest = { page: 1, size: 10 };
export function isSeperatorPresent(value: string): boolean {
	return SEPERATOR_REGX.test(value);
}

export function isSeperatorMatch(value: string): boolean {
	return Boolean(value.match(SEPERATOR_REGX));
}

export function getDaysDifference(dates: DateValues): number {
	return Math.abs(moment(Object.values(dates)[1]).diff(moment(Object.values(dates)[0]), 'days'));
}

// generate filter key suggestions list by pattern
export function getKeySuggestionsByPattern(props: keySuggestionProps): FilterOption[] {
	const filter: FilterOption[] = props.keys.filter((key) =>
		key?.pattern?.test(props.userInput.toLowerCase())
	);
	return filter.length === 0 ? props.keys.filter((key) => key.type === 'string') : filter;
}

// generate filter key suggestions list
export function getKeySuggestions(props: keySuggestionProps): FilterOption[] {
	return props.keys.filter(
		(key) => key.label.toLowerCase().indexOf(props.userInput.toLowerCase()) > -1
	);
}

// generate filter suggestions list
export function getFilteredSuggestions(props: FilteredSuggestionProps): SuggestionValues[] {
	if (props.suggestions.length !== 0) {
		for (const _key in props.suggestions) {
			if (props.userInput.split(SEPERATOR)[0] === props.suggestions[_key].key) {
				return props.suggestions[_key].values.filter((valueName) => {
					return (
						valueName.displayMember.toLowerCase().indexOf(props.userInputValue.toLowerCase()) > -1
					);
				});
			}
		}
	}
	return [];
}

// generate date object to show date picker
export function generateDateKeys(props: DateKeysProps): DateValues {
	const _date: DateValues = {};

	switch (props.selectedKey.type) {
		case 'date_range':
			for (const i in props.suggestions) {
				if (props.selectedKey.value === props.suggestions[i].ref) {
					for (const j in props.suggestions[i].values) {
						for (const k in Object.keys(props.suggestions[i].values[j].valueMember)) {
							_date[Object.keys(props.suggestions[i].values[j].valueMember)[k]] = moment(
								Object.values(props.suggestions[i].values[j].valueMember)[k] as string
							).format('YYYY-MM-DD');
						}
					}
					break;
				}
			}
			break;

		case 'date':
			_date[props.selectedKey.value] = moment(new Date()).format('YYYY-MM-DD');
			break;

		default:
			break;
	}
	return _date;
}

export function genearateSaveFilterResults(props: GenearateSaveFilterResultsProps): SaveFilters[] {
	const saveFilters: SaveFilters[] = [];
	for (const key in props.appliedFilters) {
		const obj: SaveFilters = {
			fieldName: props.appliedFilters[key].key,
			type: props.appliedFilters[key].type,
			value: '',
		};

		switch (props.appliedFilters[key].type) {
			case 'suggestion':
				if (props.appliedFilters[key]?.selectedSuggestion === null) {
					obj.value = props.appliedFilters[key].value;
					saveFilters.push(obj);
				}
				break;

			case 'date_range':
				obj.value = Object.values(
					props.appliedFilters[key]?.selectedSuggestion?.valueMember as object
				).join(',');
				saveFilters.push(obj);
				break;

			default:
				obj.value = props.appliedFilters[key].value;
				saveFilters.push(obj);
				break;
		}
	}
	return saveFilters;
}

export function searchFilterGenerator(filters: SaveFilter[]): string {
	const search: { [key: string]: string } = {};

	for (const key in filters) {
		search[filters[key].fieldName] = filters[key].value;
	}

	return new URLSearchParams(search).toString();
}

// To create search query params string
export function genearateSearchQuery(props: GenearateSearchQueryProps): string {
	let search: Record<string, string | string[]> = {};
	const valuesToMerge = [];
	for (const key in props.appliedFilters) {
		switch (props.appliedFilters[key].type) {
			case 'suggestion':
				if (props.appliedFilters[key].selectedSuggestion === null) {
					valuesToMerge.push({ [props.appliedFilters[key].key]: props.appliedFilters[key].value });
				} else {
					valuesToMerge.push({
						[props.appliedFilters[key].key]: Object.values(
							props.appliedFilters[key]?.selectedSuggestion?.valueMember as object
						).join(','),
					});
				}
				break;

			case 'date_range':
				valuesToMerge.push({
					[props.appliedFilters[key].key]: Object.values(
						props?.appliedFilters[key]?.selectedSuggestion?.valueMember as DateValues
					).join(','),
				});
				break;

			default:
				valuesToMerge.push({ [props.appliedFilters[key].key]: props.appliedFilters[key].value });
				break;
		}
	}

	search = objectMerger(valuesToMerge);
	if (props.allowPagination) {
		search['page'] = String(props.pageRequest.page);
		search['size'] = String(props.pageRequest.size);
	}

	return createQueryString(search);
}

// To create applied filter
export function generateAppliedFilters(props: GenerateAppliedFiltersProps): GenerateAppliedFilters {
	const appliedFilters: AppliedFilter[] = [];

	if (Object.keys(props.searchObject).length !== 0) {
		for (const searchKey in props.searchObject) {
			if (searchKey === 'page' || searchKey === 'size') {
				props.pageRequest[searchKey] = parseInt(props.searchObject[searchKey] as string);
			} else {
				const values: string[] =
					typeof props.searchObject[searchKey] === 'string'
						? ([props.searchObject[searchKey]] as string[])
						: (props.searchObject[searchKey] as string[]);
				for (const v in values) {
					for (const i in props.keys) {
						if (searchKey === props.keys[i].value) {
							const filterObj: AppliedFilter = {
								key: props.keys[i].value,
								label: props.keys[i].label,
								type: props.keys[i].type,
								value: '',
								selectedSuggestion: null,
								isValid: false,
							};

							switch (props.keys[i].type) {
								case 'suggestion':
									for (const j in props.suggestions) {
										if (props.keys[i].value === props.suggestions[j].ref) {
											if (props.keys[i].suggestionType === 'dynamic') {
												filterObj.value = values[v];
												filterObj.isValid = true;
											} else {
												for (const k in props.suggestions[j].values) {
													if (
														values[v] ===
														Object.values(props.suggestions[j].values[k].valueMember).join(',')
													) {
														filterObj.value = props.suggestions[j].values[k].displayMember;
														filterObj.selectedSuggestion = props.suggestions[j].values[k];
														filterObj.isValid = true;
													}
												}
												break;
											}
										}
									}
									break;

								case 'date_range':
									for (const j in props.suggestions) {
										if (props.keys[i].value === props.suggestions[j].ref) {
											for (const k in props.suggestions[j].values) {
												const date: DateValues = {};

												for (const l in Object.keys(props.suggestions[j].values[k].valueMember)) {
													date[Object.keys(props.suggestions[j].values[k].valueMember)[l]] =
														values[v].split(',')[l];
												}

												filterObj.value = values[v].replace(',', ' - ');
												filterObj.selectedSuggestion = props.suggestions[j].values[k];
												filterObj.selectedSuggestion['valueMember'] = date;

												if (props.keys[i].maxDays !== undefined) {
													if ((props.keys[i].maxDays as number) > getDaysDifference(date)) {
														filterObj.isValid = true;
													} else {
														filterObj.isValid = false;
													}
												} else {
													filterObj.isValid = true;
												}
											}
											break;
										}
									}
									break;

								default:
									if (values[v].trim() !== '') {
										filterObj.value = values[v];
										filterObj.isValid = true;
									} else {
										filterObj.value = values[v];
										filterObj.isValid = false;
									}
									break;
							}
							appliedFilters.push(filterObj);
						}
					}
				}
			}
		}
		return { pageRequest: props.pageRequest, appliedFilters };
	} else {
		return {
			pageRequest: {
				...props.pageRequest,
				page: 1,
			},
			appliedFilters,
		};
	}
}
/**
 * This is Search request generator to convert applied filters to request params
 * @param {AppliedFilter[]} filter
 * @param {PageRequest} pageRequest
 * @param {'queryString' | 'parse'} returnType - default value is parse
 */
export function searchRequestGenerator(
	filter: AppliedFilter[],
	pageRequest?: PageRequest,
	returnType?: 'queryString' | 'parse'
): SearchRequest | string {
	let filterRequest: SearchRequest = {} as SearchRequest;
	const valuesToMerge: Record<string, string | string[]>[] = [];
	for (const data in filter) {
		switch (filter[data].type) {
			case 'suggestion':
				if (filter[data].isValid) {
					if (filter[data].selectedSuggestion === null) {
						valuesToMerge.push({ [filter[data].key]: filter[data].value });
					} else {
						for (const field in filter[data].selectedSuggestion?.valueMember) {
							valuesToMerge.push({
								[field]: String(filter[data]?.selectedSuggestion?.valueMember[field]),
							});
						}
					}
				}
				break;
			case 'date_range':
				for (const field in filter[data].selectedSuggestion?.valueMember) {
					valuesToMerge.push({
						[field]: String(filter[data]?.selectedSuggestion?.valueMember[field]),
					});
				}
				break;

			default:
				valuesToMerge.push({ [filter[data].key]: filter[data].value });
				break;
		}
		filterRequest = objectMerger(valuesToMerge);
	}
	if (pageRequest?.page !== undefined) filterRequest['page'] = pageRequest.page;
	if (pageRequest?.size !== undefined) filterRequest['size'] = pageRequest.size;

	return returnType === 'queryString' ? createQueryString(filterRequest) : filterRequest;
}

/**
 *
 * @param array
 * @returns Record<string, string[]
 */
export function objectMerger(array: Record<string, string | string[]>[]): Record<string, string[]> {
	const result: Record<string, string[]> = {};
	array.forEach((obj: Record<string, string | string[]>) => {
		const key = Object.keys(obj)[0];
		let value = Object.values(obj)[0];

		const existing = Object.entries(result).filter(([k]) => k === key);
		if (existing.length) {
			result[key] = result[key].concat(value);
		} else {
			if (typeof value === 'string') {
				value = [value];
			}
			result[key] = value;
		}
	});
	return result;
}

/**
 * This is funtion is use for get element number using page and size
 */
export function generateElementCount(
	index: number | string,
	page: number | string,
	size: number | string
): number {
	return (
		(Number(index) + 1) * Number(page) * (Number(size) / (Number(index) + 1)) + (Number(index) + 1)
	);
}

/**
 * This is funtion is use for get showing results using page, size and totalElements
 */
export function generateShowingResult(
	page: number,
	size: number,
	totalElements: number | string,
	numberOfElements: number
): string {
	return `${totalElements === 0 ? totalElements : generateFirstElementNumber(Number(page), size)} - ${generateLastElementNumber(
		Number(page),
		size,
		numberOfElements
	)} of ${totalElements}`;
}

/**
 * This is funtion is use for get Last element using page and size
 */
export function generateLastElementNumber(
	page: number,
	size: number,
	numberOfElements: number
): number {
	return generateFirstElementNumber(page, size) + numberOfElements - 1;
}

/**
 * This is funtion is use for get first element using page and size
 */
export function generateFirstElementNumber(page: number | string, size: number | string): number {
	return Number(page) * Number(size) - Number(size) + 1;
}

export const parseQueryString = (query: string) => {
	const searchParams = new URLSearchParams(query);
	const object: { [key: string]: string | string[] } = {};
	searchParams.forEach((value, key) => {
		if (object[key] !== undefined && object[key] !== null) {
			let newValue: string[] = [];
			if (typeof object[key] === 'string') {
				newValue = [object[key] as string];
			} else {
				newValue = object[key] as string[];
			}
			object[key] = [...newValue, value];
		} else {
			object[key] = value;
		}
	});
	return object;
};

export const createQueryString = (object: SearchRequest) => {
	const searchParams = new URLSearchParams();
	for (const key in object) {
		if (object[key] !== undefined && object[key] !== null) {
			if (typeof object[key] === 'string') {
				searchParams.append(key, object[key] as string);
			} else if (typeof object[key] === 'number') {
				searchParams.append(key, String(object[key]));
			} else {
				for (const value of object[key] as string[]) {
					searchParams.append(key, value);
				}
			}
		}
	}
	return searchParams.toString();
};
