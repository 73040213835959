import { FC, memo } from 'react';
import { Image, makeStyles, tokens } from '@fluentui/react-components';

import { useUploadFileApi } from '_hooks/common/fileHandeler';
import { Attachment } from '_types/common';

import Button from './button';
import { useSnackbar } from './snackbar';

export type Action = 'on-upload' | 'on-delete';

export interface UploaderProps {
	value: Attachment[];
	isLoading?: boolean;
	files?: Attachment[] | string[];
	onChange?: (files: Attachment[]) => void;
	accept?: string;
	multiple?: boolean;
	reset?: boolean;
}

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		gap: tokens.spacingVerticalL,
	},
});

const Uploader: FC<UploaderProps> = (props): JSX.Element => {
	const classes = useStyles();
	const UploadFileApi = useUploadFileApi();
	const { showSnackbar } = useSnackbar();

	const uploadFile = (files: FileList) => {
		if (files !== null) {
			/* 	const _files: Attachment[] = [];

			for (let i = 0; i < files.length; i++) {
				_files.push({
					originalName: files[i].name,
					path: files[i].webkitRelativePath,
					type: files[i].type,
				});
			}
 */
			UploadFileApi.mutateAsync({ files })
				.then((res) => {
					props.onChange?.(res.data);
					showSnackbar({ message: 'Files uploded successfully.', intent: 'success' });
				})
				.catch((error) => {
					showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
				});
		}
	};

	const onChangeDetect = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) uploadFile(event.target.files);
	};

	/* 	const onDeleteClick = (file: Attachment) => () => {
		const currentFileIndex = filesResponse.indexOf(file);
		const newFiles = [...filesResponse];

		newFiles.splice(currentFileIndex, 1);
		setFilesResponse(newFiles);
		onChange?.(newFiles, 'on-delete');
	}; */

	return (
		<div className={classes.wrapper}>
			<label htmlFor='attatch-files'>
				<input
					accept={props.accept}
					disabled={UploadFileApi.isPending}
					id='attatch-files'
					max={5}
					multiple={props.multiple}
					name='attatch-files'
					style={{ display: 'none' }}
					type='file'
					onChange={onChangeDetect}
					onClick={(event) => (event.currentTarget.value = '')}
				/>

				<Button
					appearance='primary'
					as='a'
					isLoading={UploadFileApi.isPending}
					size='large'
				>
					Upload
				</Button>
			</label>
			{props.value.length !== 0 && (
				<div>
					{props.value?.map((a: Attachment) => (
						<div key={a.name}>
							<Image
								alt={a.name}
								shape='rounded'
								src={`${process.env.REACT_APP_SERVER_URL as string}${a.path}`}
								width={300}
							/>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default memo(Uploader);
