import {
	Link,
	Toast,
	Toaster,
	ToastIntent,
	ToastTitle,
	ToastTrigger,
	useId,
	useToastController,
} from '@fluentui/react-components';
import { FC, ReactNode, createContext, useContext } from 'react';
type ShowSnackbarProps = { message: string; intent: ToastIntent };
type SnackbarContextType = {
	showSnackbar: (props: ShowSnackbarProps) => void;
};
const SnackbarContext = createContext<SnackbarContextType>({
	// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
	showSnackbar: (props: ShowSnackbarProps) => {},
});
export const useSnackbar = (): SnackbarContextType =>
	useContext<SnackbarContextType>(SnackbarContext);

export const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }): JSX.Element => {
	const toasterId = useId('toaster');
	const { dispatchToast } = useToastController(toasterId);
	const showSnackbar = ({ message, intent }: ShowSnackbarProps) => {
		dispatchToast(
			<Toast>
				<ToastTitle
					action={
						<ToastTrigger>
							<Link>Dismiss</Link>
						</ToastTrigger>
					}
				>
					{message}
				</ToastTitle>
			</Toast>,
			{ intent }
		);
	};
	return (
		<SnackbarContext.Provider value={{ showSnackbar }}>
			{children}
			<Toaster
				toasterId={toasterId}
				position='top-end'
				timeout={5000}
			/>
		</SnackbarContext.Provider>
	);
};
