import {
	Accordion,
	AccordionHeader,
	AccordionItem,
	AccordionPanel,
	Caption1Strong,
	Caption1Stronger,
	Checkbox,
	Divider,
	Field,
	makeStyles,
	Subtitle2Stronger,
	tokens,
} from '@fluentui/react-components';

import InputField, { InputFieldProps } from '_components/inputField';
import { OPTIONS } from '_types/questionBankTypes';
import {
	ClassSubQuestionsFields,
	ClassQuestionFields,
	ClassQuestionMappingFields,
} from '_hooks/class/classQuestion';
import ClassFormHeader from '_components/classFormHeader';
import { FC, ReactNode, useState } from 'react';
import Button from '_components/button';
import { AddIcon, DeleteIcon } from '_components/icons';

const useStyles = makeStyles({
	formWrapper: {
		display: 'flex',
		gap: tokens.spacingVerticalL,
		flexDirection: 'column',
	},
	optionAnswerWrapper: {
		display: 'flex',
		flex: 'auto',
		gap: tokens.spacingVerticalL,
	},
	accHeader: { display: 'flex', flex: 'auto', justifyContent: 'space-between' },
	actionButtons: { display: 'flex', gap: tokens.spacingVerticalS },
});

type QuestionFormFieldsProps = {
	callFrom: 'Add' | 'Edit' | 'SubQue';
	formData: ClassQuestionFields;
	onChange: InputFieldProps['onChange'];
	setFormData: (value: React.SetStateAction<ClassQuestionFields>) => void;
	intialMapping: ClassQuestionMappingFields;
	onDeleteMapping?: (id: string) => Promise<void>;
	mappingAddon?: ReactNode;
};

export const QuestionFormFields: FC<QuestionFormFieldsProps> = (props) => {
	const classes = useStyles();

	return (
		<>
			<div className={classes.formWrapper}>
				<QuestionMappingFields {...(props as any)} />
				<QuestionFields
					formData={props.formData}
					onChange={props.onChange}
				/>
			</div>
		</>
	);
};

type SubQuestionFormFieldsProps = {
	callFrom: 'Add' | 'Edit' | 'SubQue';
	formData: ClassSubQuestionsFields;
	setFormData: (value: React.SetStateAction<ClassSubQuestionsFields>) => void;
	intialMapping: ClassQuestionMappingFields;
	intialSubQuestion: Omit<ClassQuestionFields, 'mapping'>;
	onDeleteMapping?: (id: string) => Promise<void>;
	onDeleteSubQue?: (id: string) => Promise<void>;
	mappingAddon?: ReactNode;
};

export const SubQuestionFormFields: FC<SubQuestionFormFieldsProps> = (props) => {
	const classes = useStyles();
	const [handleAcc, setHandleAcc] = useState(0);

	const onSubQuestionChange = (index: number) => (fieldName: string, value: any) => {
		const updatedState = props.formData.subQuestions.map((s, i) => {
			if (i === index) {
				return {
					...s,
					[fieldName]: fieldName === 'videoUrls' ? (value as string).split(',') : value,
				};
			} else {
				return s;
			}
		});

		props.setFormData({
			...props.formData,
			subQuestions: updatedState,
		});
	};

	const onChange = (fieldName: string, value: any) => {
		props.setFormData({
			...props.formData,
			[fieldName]: value,
		});
	};

	const onAddClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setHandleAcc(handleAcc + 1);
		props.setFormData({
			...props.formData,
			subQuestions: [...props.formData.subQuestions, props.intialSubQuestion],
		});
	};

	const onRemoveClick =
		(index: number, id?: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.stopPropagation();

			void props.onDeleteSubQue?.(id ?? '').then(() => {
				if (!id) {
					const _state = [...props.formData.subQuestions];
					_state.splice(index, 1);
					props.setFormData({
						...props.formData,
						subQuestions: _state,
					});
				}
				setHandleAcc(index);
			});
		};
	return (
		<>
			<div className={classes.formWrapper}>
				<QuestionMappingFields {...(props as any)} />

				<InputField
					fieldName='question'
					fieldProps={{ label: 'Question', placeholder: 'Add question', required: true }}
					fieldType='editor'
					value={props.formData?.question}
					onChange={onChange}
				/>
				<InputField
					fieldName='questionInstruction'
					fieldProps={{
						label: 'question instruction',
						placeholder: 'Add question instruction',
						required: true,
					}}
					fieldType='editor'
					value={props.formData?.questionInstruction}
					onChange={onChange}
				/>
				<Accordion
					multiple
					defaultOpenItems={'0'}
					openItems={[handleAcc.toString()]}
					onToggle={(e, d) => setHandleAcc(Number(d.value))}
				>
					{props.formData.subQuestions.map((q, i) => (
						<AccordionItem
							value={i.toString()}
							key={i}
						>
							<AccordionHeader>
								<div className={classes.accHeader}>
									<Subtitle2Stronger> Question {i + 1}</Subtitle2Stronger>
									<div>
										<div className={classes.actionButtons}>
											<Button
												title='Add sub question'
												appearance='subtle'
												size='small'
												icon={<AddIcon />}
												disabled={props.formData.subQuestions.length > i + 1}
												onClick={onAddClick}
											/>

											<Button
												title='Delete sub question'
												appearance='subtle'
												size='small'
												icon={<DeleteIcon color={tokens.colorStatusDangerBackground3} />}
												disabled={props.formData.subQuestions.length === 1}
												onClick={onRemoveClick(i, q._id)}
											/>
										</div>
									</div>
								</div>
							</AccordionHeader>
							<AccordionPanel>
								<QuestionFields
									key={i}
									formData={q}
									onChange={onSubQuestionChange(i)}
								/>
							</AccordionPanel>
						</AccordionItem>
					))}
				</Accordion>
			</div>
		</>
	);
};

interface QuestionFieldsProps {
	formData: Omit<ClassQuestionFields, 'mapping'>;
	onChange: InputFieldProps['onChange'];
}
const QuestionFields: FC<QuestionFieldsProps> = (props) => {
	const classes = useStyles();
	return (
		<>
			<InputField
				fieldName='question'
				fieldProps={{ label: 'Question', placeholder: 'Add question', required: true }}
				fieldType='editor'
				value={props.formData?.question}
				onChange={props.onChange}
			/>
			<InputField
				fieldName='answer'
				fieldProps={{ label: 'Answer', placeholder: 'Add answer', required: true }}
				fieldType='editor'
				value={props.formData?.answer}
				onChange={props.onChange}
			/>

			<InputField
				fieldName='videoUrls'
				fieldProps={{ label: 'Video urls', placeholder: 'Add video urls' }}
				fieldType='text'
				value={props.formData?.videoUrls?.join(', ') ?? ''}
				onChange={props.onChange}
			/>

			{Object.keys(OPTIONS).map((option) => {
				return (
					<InputField
						key={`option.${option}`}
						fieldName={`option.${option}`}
						fieldProps={{ label: `Option ${option}`, placeholder: `Insert option ${option}` }}
						fieldType='editor'
						value={
							(props.formData?.[
								`option.${option}` as keyof Omit<ClassQuestionFields, 'mapping'>
							] as string) ?? ''
						}
						onChange={props.onChange}
					/>
				);
			})}

			<div className={classes.optionAnswerWrapper}>
				<Field label={<Caption1Stronger children={'Option answer'} />} />
				{Object.keys(OPTIONS).map((option) => (
					<Checkbox
						key={`checkbox-${option}`}
						checked={props.formData?.optionsAnswer?.some((o) => o === option)}
						label={<Caption1Strong>{`Option ${option}`}</Caption1Strong>}
						onChange={(ev, data) =>
							props.onChange?.(
								'optionsAnswer',
								data.checked
									? [...(props.formData?.optionsAnswer ?? []), option]
									: props.formData?.optionsAnswer?.filter((o) => o !== option)
							)
						}
					/>
				))}
			</div>
			<InputField
				fieldName='description'
				fieldProps={{ label: 'Description', placeholder: 'Add description' }}
				fieldType='editor'
				value={props.formData?.description ?? ''}
				onChange={props.onChange}
			/>
			<InputField
				fieldName='extraDescription'
				fieldProps={{ label: 'Extra description', placeholder: 'Add extra description' }}
				fieldType='editor'
				value={props.formData?.extraDescription ?? ''}
				onChange={props.onChange}
			/>
		</>
	);
};

interface QuestionMappingFieldsProps {
	callFrom: 'Add' | 'Edit' | 'SubQue';
	formData: ClassQuestionFields | ClassSubQuestionsFields;
	onChange: InputFieldProps['onChange'];
	setFormData: (
		value: React.SetStateAction<ClassQuestionFields> | React.SetStateAction<ClassSubQuestionsFields>
	) => void;
	intialMapping: ClassQuestionMappingFields;
	onDeleteMapping?: (id: string) => Promise<void>;
	mappingAddon?: ReactNode;
}
const QuestionMappingFields: FC<QuestionMappingFieldsProps> = (props) => {
	const onMappingInputChange = (index: number) => (fieldName: string, value: any) => {
		const updatedState = props.formData.mapping.map((s, i) => {
			if (i === index) {
				return { ...s, [fieldName]: value };
			} else {
				return s;
			}
		});

		props.setFormData({
			...props.formData,
			mapping: updatedState,
		});
	};

	const onAddClick = () => {
		props.setFormData({
			...props.formData,
			mapping: [...props.formData.mapping, props.intialMapping],
		});
	};

	const onRemoveClick = (index: number, id: string) => {
		void props.onDeleteMapping?.(id).then(() => {
			if (!id) {
				const _state = [...props.formData.mapping];
				_state.splice(index, 1);
				props.setFormData({
					...props.formData,
					mapping: _state,
				});
			}
		});
	};
	return (
		<>
			<Field label={<Caption1Stronger children={'Question mapping'} />} />
			{props.formData.mapping.map((mapping, index) => (
				<>
					<div
						style={{ display: 'flex', gap: 8 }}
						key={index}
					>
						<Subtitle2Stronger>{index + 1}.</Subtitle2Stronger>
						<ClassFormHeader
							key={index}
							formData={mapping}
							onChange={onMappingInputChange(index)}
						/>
						<div style={{ display: 'flex', gap: 4, alignSelf: 'self-end' }}>
							<Button
								title='Add mapping'
								appearance='subtle'
								size='small'
								icon={<AddIcon />}
								disabled={props.formData.mapping.length > index + 1}
								onClick={onAddClick}
							/>

							<Button
								title='Delete mapping'
								appearance='subtle'
								size='small'
								icon={<DeleteIcon color={tokens.colorStatusDangerBackground3} />}
								disabled={props.formData.mapping.length === 1}
								onClick={() => onRemoveClick(index, mapping._id as string)}
							/>
						</div>
					</div>
					<Divider />
				</>
			))}
			{props.mappingAddon}
		</>
	);
};
