import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiSuccessResponse } from '_types/common';
import axios from 'axios';

export const QuestionSetOption: QuestionSetType[] = ['PRACTICE', 'PROBLEM'];

export type QuestionSetType = 'PRACTICE' | 'PROBLEM';
export interface QuestionSetResponse {
	_id: string;
	name: string;
	type: QuestionSetType[];
}

export interface QuestionSetRequest {
	name: string;
	type: QuestionSetType[] | string[];
}
export const useGetQuestionSetApi = (enabled?: boolean) =>
	useQuery({
		queryKey: ['questionSet'],
		queryFn: () =>
			axios<QuestionSetResponse[]>({
				method: 'GET',
				url: `/api/class/questionSet`,
			}),
		enabled,
	});

export const useAddQuestionSetApi = () =>
	useMutation({
		mutationFn: (info: { request: QuestionSetRequest }) =>
			axios<ApiSuccessResponse>({
				method: 'POST',
				url: `/api/class/questionSet`,
				data: info.request,
			}),
	});

export const useUpdateQuestionSetApi = () =>
	useMutation({
		mutationFn: (info: { request: QuestionSetRequest; id: string }) =>
			axios<ApiSuccessResponse>({
				method: 'PUT',
				url: `/api/class/questionSet/${info.id}`,
				data: info.request,
			}),
	});
