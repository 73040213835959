import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { FC, memo } from 'react';

interface HtmlPreviewProps {
	html?: string | TrustedHTML;
	backgroundColor?: boolean;
	padding?: boolean;
	className?: string;
}
const useStyles = makeStyles({
	htmlPreview: {
		...shorthands.borderRadius(tokens.borderRadiusMedium),
	},
	backgroundColor: {
		backgroundColor: tokens.colorBrandBackground2,
	},
	padding: {
		...shorthands.padding(tokens.spacingVerticalL),
	},
});

const HtmlPreview: FC<HtmlPreviewProps> = (props) => {
	const classes = useStyles();
	return (typeof props.html === 'string' ? props.html.trim() : props.html) ? (
		<div
			className={mergeClasses(
				'ql-editor',
				classes.htmlPreview,
				props.backgroundColor && classes.backgroundColor,
				props.padding && classes.padding,
				props.className && props.className
			)}
			dangerouslySetInnerHTML={{ __html: props.html ?? '' }}
		/>
	) : (
		<></>
	);
};
export default memo(HtmlPreview);
