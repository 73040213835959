import { memo, useState } from 'react';
import { Title3 } from '@fluentui/react-components';

import { optionsToValues } from '_utils/common';
import { Options } from '_types/questionBankTypes';
import Button from '_components/button';
import {
	ClassQuestionFields,
	ClassQuestionMappingFields,
	useSaveQuestionApi,
} from '_hooks/class/classQuestion';
import { useSnackbar } from '_components/snackbar';
import { ApiError, Option } from '_types/common';
import Header from '_components/header';
import { useCommonStyles } from '_utils/styles';
import { mappingFieldsToRequest } from '_hooks/class/common';
import { SaveIcon } from '_components/icons';
import { QuestionFormFields } from './questionForm';
import { useAlertDialog } from '_components/alertDialog';

const intialMapping: ClassQuestionMappingFields = {
	className: {} as Option,
	board: {} as Option,
	chapter: {} as Option,
	subject: {} as Option,
	section: {} as Option,
	sessions: [],
};

const intialFormData: ClassQuestionFields = {
	question: '',
	answer: '',
	'option.a': '',
	'option.b': '',
	'option.c': '',
	'option.d': '',
	'option.e': '',
	optionsAnswer: [],
	description: '',
	extraDescription: '',
	videoUrls: [],
	questionSets: [],
	mapping: [intialMapping],
};
const AddClassQuestion = () => {
	const { showSnackbar } = useSnackbar();
	const { showAlertDialog, hideAlertDialog } = useAlertDialog();
	const classes = useCommonStyles();
	const [formData, setFormData] = useState<ClassQuestionFields>(intialFormData);

	const SaveQuestionApi = useSaveQuestionApi();

	const onChange = (fieldName: keyof ClassQuestionFields | string, value: any) => {
		if (fieldName === 'videoUrls') {
			setFormData({
				...formData,
				[fieldName]: (value as string).split(','),
			});
			return;
		}

		setFormData({
			...formData,
			[fieldName]: value,
		});
	};

	const onResetClick = () => {
		setFormData(intialFormData);
	};

	const onSaveClick = () => {
		const options: Options = {
			a: formData['option.a'],
			b: formData['option.b'],
			c: formData['option.c'],
			d: formData['option.d'],
			...(formData['option.e'] && { e: formData['option.e'] }),
		};

		SaveQuestionApi.mutateAsync({
			request: {
				answer: formData.answer,
				question: formData.question,
				...(Object.values(options).every((o) => o.trim() !== '') && { options }),
				...(formData.description?.trim() && { description: formData.description }),
				...(formData.extraDescription?.trim() && { extraDescription: formData.extraDescription }),
				...(formData.optionsAnswer?.length !== 0 && { optionsAnswer: formData.optionsAnswer }),
				...(formData.videoUrls?.length !== 0 && { videoUrls: formData.videoUrls }),
				...(formData.questionSets?.length !== 0 && {
					questionSet: optionsToValues(formData.questionSets ?? []),
				}),
				mapping: mappingFieldsToRequest(formData.mapping),
			},
		})
			.then((res) => {
				onResetClick();
				showSnackbar({ message: res.data.message, intent: 'success' });
			})
			.catch((error: ApiError) => {
				showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
			});
	};
	const onDeleteMapping = (): Promise<void> => {
		return new Promise((resolve) => {
			showAlertDialog({
				title: 'Do you want to delete question mapping?',
				buttons: [
					{
						children: 'No',
						onClick: () => {
							hideAlertDialog();
						},
					},
					{
						children: 'Yes',
						appearance: 'primary',
						onClick: () => {
							hideAlertDialog();
							resolve();
						},
					},
				],
			});
		});
	};
	return (
		<>
			<Header title={<Title3>Add Class Questions</Title3>} />
			<div className={classes.mainContent}>
				<QuestionFormFields
					callFrom='Add'
					formData={formData}
					onChange={onChange}
					setFormData={setFormData}
					intialMapping={intialMapping}
					onDeleteMapping={onDeleteMapping}
				/>
			</div>
			<div className={classes.stickyFooter}>
				<div className={classes.footerWrapper}>
					<Button
						appearance='outline'
						onClick={onResetClick}
					>
						Reset
					</Button>
					<Button
						appearance='primary'
						icon={<SaveIcon />}
						isLoading={SaveQuestionApi.isPending}
						onClick={onSaveClick}
					>
						Save
					</Button>
				</div>
			</div>
		</>
	);
};

export default memo(AddClassQuestion);
