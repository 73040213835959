/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Option } from '_types/common';
import { useRef } from 'react';

// eslint-disable-next-line import/no-unused-modules
export const flattenObject = (obj: Record<string, any>, prefix: string | undefined = '') => {
	let flattenedObj: Record<string, string> = {};

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const prefixedKey = prefix ? `${prefix}.${key}` : key;

			if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
				const nestedObj = flattenObject(obj[key], prefixedKey);
				flattenedObj = { ...flattenedObj, ...nestedObj };
			} else {
				flattenedObj[prefixedKey] = obj[key];
			}
		}
	}

	return flattenedObj;
};

export const debounce = (cb: any, ms = 300) => {
	let timeoutId: ReturnType<typeof setTimeout>;
	return function (this: any, ...args: any[]) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => cb.apply(this, args), ms);
	};
};

export const BoardOption = ['NCERT', 'CBSC', 'Maharashtra State Board'];
export const ClassOption = ['Class 9', 'Class 10', 'Class 11', 'Class 12'];
/* 
const previousFinancialYears = Array.from(
	{ length: 3 },
	(_, count) => `${new Date().getFullYear() - count - 1}-${new Date().getFullYear() - count}`
).reverse();

const currentFinancialYear =
	new Date().getMonth() + 1 <= 3
		? `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`
		: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`;

export const YearOption: Option[] = [...previousFinancialYears, currentFinancialYear].map((v) => ({
	label: v,
	value: v,
})); */
export const SeasonOption: Option[] = [
	{ label: 'Summer', value: 'Summer' },
	{ label: 'Winter', value: 'Winter' },
];

export const Years = Array.from(
	{ length: 3 },
	(_, count) => `${new Date().getFullYear() - count - 1}`
)
	.reverse()
	.concat(new Date().getFullYear().toString());

export const SessionOptons = Years.flatMap((year) =>
	SeasonOption.map((season) => ({
		value: `${season.value} - ${year}`,
		label: `${season.label} - ${year}`,
	}))
);

export const SectionOption: Option[] = ['A', 'B', 'C', 'D'].map((v) => ({
	label: v,
	value: v,
}));

export const optionsToValues = (options: Option[]) => options.map((option) => option.value);

export const useCaptureTripleClick = (callback: (element: HTMLElement) => void) => {
	const elementsRef = useRef(new Map());

	/* const handleClick = (element: HTMLElement) => () => {
		const elementData = elementsRef.current.get(element);

		///	elementData.clickCount += 1;

		if (elementData.clickCount === 3) {
			callback(element);
			console.log('dfkjvnfjvkd', elementData);
			elementData.clickCount = 0;
		}

		if (elementData.timer) {
			clearTimeout(elementData.timer);
		}

		elementData.timer = setTimeout(() => {
			elementData.clickCount = 0;
		}, 500);

		elementsRef.current.set(element, elementData);
	};
 */
	const addListenerForTripleClick = (element: HTMLElement) => {
		if (element) {
			elementsRef.current.set(element, { clickCount: 0, timer: null });
			element.addEventListener('dblclick', () => callback(element));
		}
	};

	const removeListenerForTripleClick = (element: HTMLElement) => {
		if (element) {
			const elementData = elementsRef.current.get(element);
			if (elementData && elementData.timer) {
				clearTimeout(elementData.timer);
			}
			element.removeEventListener('dblclick', () => callback(element));
			elementsRef.current.delete(element);
		}
	};
	return { addListenerForTripleClick, removeListenerForTripleClick };
};
