import {
	Button as Button_,
	ButtonProps as ButtonProps_,
	Spinner,
	SpinnerProps,
} from '@fluentui/react-components';
import { FC, memo } from 'react';

export type ButtonProps = ButtonProps_ & {
	isLoading?: boolean;
	spinnerProps?: SpinnerProps;
};
const Button: FC<ButtonProps> = (props) => {
	return (
		<Button_
			disabled={props.isLoading}
			{...props}
		>
			{props.isLoading ? (
				<Spinner
					size='tiny'
					{...props.spinnerProps}
				/>
			) : (
				props.children
			)}
		</Button_>
	);
};

export default memo(Button);
