import { QuestionCategoryWithId } from './questionCategoryTypes';
import { Attachment, Id, PageRequest, PaginationResponse } from './common';

export type Options = {
	a: string;
	b: string;
	c: string;
	d: string;
	e?: string;
};
export const OPTIONS: Options = { a: '', b: '', c: '', d: '', e: '' };

export type SolutionAttachment = Omit<Attachment, 'originalName' | 'path' | 'status' | 'error'>;
