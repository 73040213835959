import {
	Body1Stronger,
	createTableColumn,
	makeStyles,
	TableCellLayout,
	TableColumnDefinition,
	Title3,
	tokens,
} from '@fluentui/react-components';
import { memo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Header from '_components/header';
import Button from '_components/button';
import Table from '_components/table';
import {
	ClassQuestionResponse,
	GetClassQuestionsRequest,
	useDeleteQuestionApi,
	useGetClassQuestionsApi,
} from '_hooks/class/classQuestion';
import HtmlPreview from '_components/htmlPreview';
import QuestionViewerDialog from '_components/questionViewerDialog';
import { useSnackbar } from '_components/snackbar';
import { ApiError } from '_types/common';
import { useAlertDialog } from '_components/alertDialog';
import { AddIcon, DeleteIcon, EditIcon, EyeIcon } from '_components/icons';
import { useCommonStyles } from '_utils/styles';
import SmartSearch, {
	FilterOption,
	searchRequestGenerator,
	Suggestion,
} from '_components/smartSearch';
import { BoardOption, ClassOption } from '_utils/common';

const useStyles = makeStyles({
	previewButton: {
		display: 'flex',
		flex: 'auto',
		gap: tokens.spacingVerticalS,
	},
	headerButton: {
		display: 'flex',
		gap: tokens.spacingVerticalM,
	},
});

const filterOptions: FilterOption[] = [
	{ label: 'Question', value: 'question', type: 'string' },
	{ label: 'Board', value: 'board', type: 'suggestion' },
	{ label: 'Class name', value: 'className', type: 'suggestion' },
	{ label: 'Chapter', value: 'chapter', type: 'string' },
	{ label: 'Subject', value: 'subject', type: 'string' },
];

const BOARD: Suggestion = {
	key: filterOptions[filterOptions.findIndex((o) => o.value === 'board')].label,
	ref: filterOptions[filterOptions.findIndex((o) => o.value === 'board')].value,
	values: BoardOption.map((s) => {
		return { displayMember: s, valueMember: { board: s } };
	}),
};

const CLASS: Suggestion = {
	key: filterOptions[filterOptions.findIndex((o) => o.value === 'className')].label,
	ref: filterOptions[filterOptions.findIndex((o) => o.value === 'className')].value,
	values: ClassOption.map((s) => {
		return { displayMember: s, valueMember: { className: s } };
	}),
};
const ClassQuestions = () => {
	const { showSnackbar } = useSnackbar();
	const { showAlertDialog, hideAlertDialog } = useAlertDialog();
	const classes = { ...useStyles(), ...useCommonStyles() };
	const natigate = useNavigate();

	const [searchRequest, setSearchRequest] = useState<GetClassQuestionsRequest>();
	const GetClassQuestionsApi = useGetClassQuestionsApi(searchRequest);
	const DeleteQuestionApi = useDeleteQuestionApi();

	const [selectedQuestion, setSelectedQuestion] = useState('');

	const onDelete = (id: string) => {
		showAlertDialog({
			title: 'Do you want to delete question?',
			buttons: [
				{
					children: 'No',
					onClick: () => {
						hideAlertDialog();
					},
				},
				{
					children: 'Yes',
					appearance: 'primary',
					onClick: () => {
						DeleteQuestionApi.mutateAsync({ id })
							.then((res) => {
								void GetClassQuestionsApi.refetch();
								showSnackbar({ message: res.data.message, intent: 'success' });
								hideAlertDialog();
							})
							.catch((error: ApiError) => {
								showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
							});
					},
				},
			],
		});
	};
	const items: ClassQuestionResponse[] = GetClassQuestionsApi.data?.data.data ?? [];
	const columns: TableColumnDefinition<ClassQuestionResponse>[] = [
		createTableColumn<ClassQuestionResponse>({
			columnId: 'preview',
			renderHeaderCell: () => {
				return <Body1Stronger>Preview</Body1Stronger>;
			},
			renderCell: ({ _id, subQuestions }) => {
				return (
					<div className={classes.previewButton}>
						<Button
							appearance='primary'
							icon={<EyeIcon />}
							size='small'
							title='See in detail'
							onClick={() => setSelectedQuestion(_id)}
						/>{' '}
						{subQuestions?.length > 0 && <Body1Stronger>Sub Q</Body1Stronger>}
					</div>
				);
			},
		}),
		createTableColumn({
			columnId: 'question',
			renderHeaderCell: () => {
				return <Body1Stronger>Question</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{<HtmlPreview html={item.question} />}</TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'boards',
			renderHeaderCell: () => {
				return <Body1Stronger>Board</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.mapping.map((m) => m.board).join(', ')}</TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'sessions',
			renderHeaderCell: () => {
				return <Body1Stronger>Session</Body1Stronger>;
			},
			renderCell: (item) => {
				return item.mapping.map((m) => m.sessions?.join(', ')).join(', ');
			},
		}),
		createTableColumn({
			columnId: 'classes',
			renderHeaderCell: () => {
				return <Body1Stronger>Class</Body1Stronger>;
			},
			renderCell: (item) => {
				return (
					<TableCellLayout>{item.mapping.map((m) => m.className).join(', ')} </TableCellLayout>
				);
			},
		}),
		createTableColumn({
			columnId: 'subjects',
			renderHeaderCell: () => {
				return <Body1Stronger>Subject</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.mapping.map((m) => m.subject).join(', ')}</TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'chapters',
			renderHeaderCell: () => {
				return <Body1Stronger>Chapters</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.mapping.map((m) => m.chapter).join(', ')}</TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'sections',
			renderHeaderCell: () => {
				return <Body1Stronger>Sections</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.mapping.map((m) => m.section).join(', ')}</TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'actions',
			renderHeaderCell: () => {
				return <Body1Stronger>Actions</Body1Stronger>;
			},
			renderCell: (item) => {
				return (
					<>
						<Button
							appearance='transparent'
							aria-label='Edit'
							icon={<EditIcon />}
							onClick={() =>
								natigate(
									item.subQuestions?.length > 0
										? `/class/edit/sub-questions/${item._id}`
										: `/class/edit/questions/${item._id}`
								)
							}
						/>
						<Button
							aria-label='Delete'
							appearance='transparent'
							icon={<DeleteIcon />}
							onClick={() => onDelete(item._id)}
						/>
					</>
				);
			},
		}),
	];

	const columnSizingOptions = {
		preview: {
			defaultWidth: 10,
		},
		question: {
			defaultWidth: 600,
		},
	};

	return (
		<>
			<Header
				content={
					<div className={classes.headerButton}>
						<Button
							appearance='primary'
							icon={<AddIcon />}
							iconPosition='before'
							onClick={() => natigate('/class/add/questions')}
						>
							Add Questions
						</Button>

						<Button
							appearance='primary'
							icon={<AddIcon />}
							iconPosition='before'
							onClick={() => natigate('/class/add/sub-questions')}
						>
							Add Sub Questions
						</Button>
					</div>
				}
				title={<Title3>Class Questions</Title3>}
			/>
			<div className={`${classes.mainContent} ${classes.mainContentGap}`}>
				<SmartSearch
					allowPagination={true}
					isDataLoading={GetClassQuestionsApi.isFetching}
					keys={filterOptions}
					suggestions={[BOARD, CLASS]}
					totalPages={Number(GetClassQuestionsApi.data?.data?.totalPages ?? 0)}
					onFilterChange={(appliedFilters, pageRequest) =>
						setSearchRequest(
							searchRequestGenerator(appliedFilters, pageRequest) as GetClassQuestionsRequest
						)
					}
					onReload={() => GetClassQuestionsApi.refetch()}
					showingResults={{
						totalElements: Number(GetClassQuestionsApi.data?.data?.totalElements ?? 0),
						totalFiltered: Number(GetClassQuestionsApi.data?.data?.filteredElements ?? 0),
						numberOfElements: Number(GetClassQuestionsApi.data?.data?.size ?? 0),
					}}
				/>
				<Table
					resizableColumns
					columnSizingOptions={columnSizingOptions}
					columns={columns}
					isLoading={GetClassQuestionsApi.isLoading}
					items={items}
					resizableColumnsOptions={{
						autoFitColumns: true,
					}}
				/>
			</div>
			<QuestionViewerDialog
				data={GetClassQuestionsApi.data?.data?.data.find(
					(d: { _id: string }) => d._id === selectedQuestion
				)}
				open={Boolean(selectedQuestion)}
				onClose={() => setSelectedQuestion('')}
			/>
		</>
	);
};

export default memo(ClassQuestions);
