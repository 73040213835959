import { FC, memo } from 'react';
import { makeStyles, tokens, Divider } from '@fluentui/react-components';

const useStyles = makeStyles({
	headerWrapper: {
		paddingTop: tokens.spacingVerticalS,
		width: '-webkit-fill-available',
		zIndex: 100,
		backgroundColor: tokens.colorNeutralBackground1,
	},
	titleDivider: {
		marginTop: tokens.spacingVerticalS,
	},
	wrapper: {
		paddingLeft: tokens.spacingVerticalL,
		paddingRight: tokens.spacingVerticalL,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	contentWrapper: {},
});

interface HeaderProps {
	title: React.ReactElement;
	content?: React.ReactElement;
}
const Header: FC<HeaderProps> = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.headerWrapper}>
			<div className={classes.wrapper}>
				{props.title}
				<div className={classes.contentWrapper}>{props.content}</div>
			</div>
			<Divider className={classes.titleDivider} />
		</div>
	);
};

export default memo(Header);
