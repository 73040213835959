import {
	Dialog,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Field,
	Input,
	makeStyles,
	tokens,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { FC, memo, useEffect, useState } from 'react';
import { useSnackbar } from '_components/snackbar';
import { ApiError, Option } from '_types/common';
import Button from '_components/button';
import {
	QuestionSetOption,
	QuestionSetResponse,
	QuestionSetType,
	useAddQuestionSetApi,
	useUpdateQuestionSetApi,
} from '_hooks/class/questionSet';
import InputField from '_components/inputField';

interface QuestionSetProps {
	open: QuestionSetResponse | boolean;
	onClose: () => void;
	refetch?: () => void;
}
const useStyles = makeStyles({
	dialogAction: { paddingTop: tokens.spacingVerticalL },
});
const QuestionSet: FC<QuestionSetProps> = (props) => {
	const classes = useStyles();
	const { showSnackbar } = useSnackbar();
	const AddQuestionSetApi = useAddQuestionSetApi();
	const UpdateQuestionSetApiUpdateQuestionSetApis = useUpdateQuestionSetApi();

	const [name, setName] = useState('');
	const [types, setTypes] = useState<QuestionSetType[] | string[]>([]);
	const isToUpdate = typeof props.open === 'boolean' ? false : true;
	const questionSet: QuestionSetResponse = props.open as QuestionSetResponse;

	useEffect(() => {
		if (isToUpdate) {
			setName(questionSet.name);
			setTypes(questionSet.type);
		}
		return () => {
			setName('');
			setTypes([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open]);

	const onSaveClick = () => {
		AddQuestionSetApi.mutateAsync({
			request: {
				name: name.trim(),
				type: types,
			},
		})
			.then((res) => {
				props.refetch?.();
				props.onClose();
				showSnackbar({ message: res.data.message, intent: 'success' });
			})
			.catch((error: ApiError) => {
				showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
			});
	};

	const onUpdateClick = () => {
		UpdateQuestionSetApiUpdateQuestionSetApis.mutateAsync({
			id: questionSet._id,
			request: {
				name: name.trim(),
				type: types,
			},
		})
			.then((res) => {
				props.refetch?.();
				props.onClose();
				showSnackbar({ message: res.data.message, intent: 'success' });
			})
			.catch((error: ApiError) => {
				showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
			});
	};

	return (
		<Dialog
			open={Boolean(props.open)}
			onOpenChange={props.onClose}
			modalType='modal'
		>
			<DialogSurface>
				<DialogBody>
					<DialogTitle
						action={
							<DialogTrigger action='close'>
								<Button
									appearance='subtle'
									aria-label='close'
									color='red'
									icon={<Dismiss24Regular />}
								/>
							</DialogTrigger>
						}
					>
						{isToUpdate ? `Update question set: ${questionSet.name}` : 'Add question sets'}
					</DialogTitle>
					<DialogContent>
						<Field
							label='Question set name'
							required
						>
							<Input
								value={name}
								onChange={(e, d) => setName(d.value)}
								required
							/>
						</Field>

						<InputField
							//	styles={{ container: classes.container, field: classes.field }}
							fieldName='questionSet'
							fieldProps={{
								size: 'medium',
								label: 'Select question set',
								placeholder: 'Select question sets',
								required: true,
							}}
							fieldType='multple-select'
							options={QuestionSetOption.map?.((b) => ({
								label: b,
								value: b,
							}))}
							value={types.map((s) => ({ label: s, value: s }))}
							onChange={(_, value: Option[]) => setTypes(value.map((s) => s.value))}
						/>
					</DialogContent>
					<DialogActions className={classes.dialogAction}>
						<DialogTrigger disableButtonEnhancement>
							<Button
								appearance='secondary'
								disabled={AddQuestionSetApi.isPending}
							>
								Close
							</Button>
						</DialogTrigger>

						{isToUpdate ? (
							<Button
								appearance='primary'
								onClick={onUpdateClick}
								isLoading={UpdateQuestionSetApiUpdateQuestionSetApis.isPending}
								disabled={name.trim().length === 0 || types.length === 0}
							>
								Update
							</Button>
						) : (
							<Button
								appearance='primary'
								onClick={onSaveClick}
								isLoading={AddQuestionSetApi.isPending}
								disabled={name.trim().length === 0 || types.length === 0}
							>
								Save
							</Button>
						)}
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
};

export default memo(QuestionSet);
