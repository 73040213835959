import { makeStyles, tokens } from '@fluentui/react-components';
import { FC, memo, useState } from 'react';

import { Option } from '_types/common';
import { useGetSubjectsApi } from '_hooks/class/subjects';
import { useGetChaptersApi } from '_hooks/class/chapter';
import { BoardOption, ClassOption, SectionOption, SessionOptons } from '_utils/common';

import InputField, { InputFieldProps } from './inputField';
import Button from './button';
import { AddIcon } from './icons';
import AddSubjectDialog from 'views/class/subjects/addSubjectDialog';
import { useGetQuestionSetApi } from '_hooks/class/questionSet';
import AddChapterDialog from 'views/class/chapters/addChapterDialog';
import AddQuestionSetDialog from 'views/class/questionSet/addQuestionSetDialog';

const useStyles = makeStyles({
	formHead: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 'auto',
		gap: tokens.spacingVerticalXXL,
		alignItems: 'self-end',
	},
	button: {
		alignSelf: 'flex-end',
	},
	container: {
		/* 	flexBasis: `32%`, */
		flexDirection: 'row',
		gap: tokens.spacingVerticalS,
		/* 		'& .overflow-menus': { maxWidth: `480px` }, */
	},
	field: { flex: 'auto' },
});

type Props = {
	formData: {
		className: Option;
		board: Option;
		chapter?: Option;
		subject?: Option;
		sessions?: Option[];
		section?: Option;
		chapters?: Option[];
		subjects?: Option[];
		questionSets?: Option[];
	};
	onChange: InputFieldProps['onChange'];
};
const ClassFormHeader: FC<Props> = (props) => {
	const classes = useStyles();

	const [isAddDialog, setIsAddDialogOpen] = useState<false | 'subject' | 'chapter' | 'questionSet'>(
		false
	);

	const GetSubjectsApi = useGetSubjectsApi();
	const GetChaptersApi = useGetChaptersApi();
	const GetQuestionSetApi = useGetQuestionSetApi(!!props.formData.questionSets);

	const handleRefetch = () => {
		if (isAddDialog === 'chapter') return void GetChaptersApi.refetch();
		if (isAddDialog === 'subject') return void GetSubjectsApi.refetch();
		if (isAddDialog === 'questionSet') return void GetQuestionSetApi.refetch();
	};
	return (
		<>
			<div className={classes.formHead}>
				<InputField
					styles={{ container: classes.container, field: classes.field }}
					fieldName='board'
					fieldProps={{ label: 'Select board', placeholder: 'Select board', required: true }}
					fieldType='select'
					options={BoardOption.map?.((b) => ({ label: b, value: b }))}
					value={props.formData?.board}
					onChange={props.onChange}
				/>

				<InputField
					styles={{ container: classes.container, field: classes.field }}
					fieldName='className'
					fieldProps={{ label: 'Select class', placeholder: 'Select class', required: true }}
					fieldType='select'
					options={ClassOption.map?.((b) => ({ label: b, value: b }))}
					value={props.formData?.className}
					onChange={props.onChange}
				/>

				{props.formData.subject && (
					<InputField
						styles={{ container: classes.container, field: classes.field }}
						fieldName='subject'
						fieldProps={{ label: 'Select subject', placeholder: 'Select subject', required: true }}
						fieldType='select'
						options={GetSubjectsApi.data?.data?.map?.((b) => ({
							label: b.name,
							value: b._id,
						}))}
						value={props.formData?.subject}
						onChange={props.onChange}
						addons={
							<Button
								className={classes.button}
								title='Add subject'
								appearance='subtle'
								size='small'
								icon={<AddIcon />}
								onClick={() => setIsAddDialogOpen('subject')}
							/>
						}
					/>
				)}

				{props.formData.chapter && (
					<InputField
						styles={{ container: classes.container, field: classes.field }}
						fieldName='chapter'
						fieldProps={{ label: 'Select chapter', placeholder: 'Select chapter', required: true }}
						fieldType='select'
						options={GetChaptersApi.data?.data?.map?.((b) => ({
							label: b.name,
							value: b._id,
						}))}
						value={props.formData?.chapter}
						onChange={props.onChange}
						addons={
							<Button
								className={classes.button}
								title='Add subject'
								appearance='subtle'
								size='small'
								icon={<AddIcon />}
								onClick={() => setIsAddDialogOpen('chapter')}
							/>
						}
					/>
				)}

				{props.formData.subjects && (
					<InputField
						styles={{ container: classes.container, field: classes.field }}
						fieldName='subjects'
						fieldProps={{
							label: 'Select subjects',
							placeholder: 'Select subjects',
							required: true,
						}}
						fieldType='multple-select'
						options={GetSubjectsApi.data?.data?.map?.((b) => ({
							label: b.name,
							value: b._id,
						}))}
						value={props.formData?.subjects}
						onChange={props.onChange}
						addons={
							<Button
								className={classes.button}
								title='Add subjects'
								appearance='subtle'
								size='small'
								icon={<AddIcon />}
								onClick={() => setIsAddDialogOpen('subject')}
							/>
						}
					/>
				)}

				{props.formData.chapters && (
					<InputField
						styles={{ container: classes.container, field: classes.field }}
						fieldName='chapters'
						fieldProps={{
							label: 'Select chapters',
							placeholder: 'Select chapters',
							required: true,
						}}
						fieldType='multple-select'
						options={GetChaptersApi.data?.data?.map?.((b) => ({
							label: b.name,
							value: b._id,
						}))}
						value={props.formData?.chapters}
						onChange={props.onChange}
						addons={
							<Button
								className={classes.button}
								title='Add subject'
								appearance='subtle'
								size='small'
								icon={<AddIcon />}
								onClick={() => setIsAddDialogOpen('chapter')}
							/>
						}
					/>
				)}

				{props.formData.sessions && (
					<InputField
						styles={{ container: classes.container, field: classes.field }}
						fieldName='sessions'
						fieldProps={{ label: 'Select sessions', placeholder: 'Select sessions' }}
						fieldType='multple-select'
						options={SessionOptons}
						value={props.formData?.sessions ?? []}
						onChange={props.onChange}
					/>
				)}

				{props.formData.section && (
					<InputField
						styles={{ container: classes.container, field: classes.field }}
						fieldName='section'
						fieldProps={{ label: 'Select section', placeholder: 'Select section' }}
						fieldType='select'
						options={SectionOption}
						value={props.formData?.section ?? []}
						onChange={props.onChange}
					/>
				)}
				{props.formData.questionSets && (
					<InputField
						styles={{ container: classes.container, field: classes.field }}
						fieldName='questionSets'
						fieldProps={{ label: 'Add to question set', placeholder: 'Select question sets' }}
						fieldType='select'
						options={GetQuestionSetApi.data?.data?.map?.((b) => ({
							label: b.name,
							value: b._id,
						}))}
						value={props.formData?.questionSets ?? []}
						onChange={props.onChange}
						addons={
							<Button
								className={classes.button}
								title='Add question set'
								appearance='subtle'
								size='small'
								icon={<AddIcon />}
								onClick={() => setIsAddDialogOpen('questionSet')}
							/>
						}
					/>
				)}
			</div>
			<AddSubjectDialog
				open={isAddDialog === 'subject'}
				onClose={() => setIsAddDialogOpen(false)}
				refetch={handleRefetch}
			/>
			<AddChapterDialog
				open={isAddDialog === 'chapter'}
				onClose={() => setIsAddDialogOpen(false)}
				refetch={handleRefetch}
			/>
			<AddQuestionSetDialog
				open={isAddDialog === 'questionSet'}
				onClose={() => setIsAddDialogOpen(false)}
				refetch={handleRefetch}
			/>
		</>
	);
};

export default memo(ClassFormHeader);
