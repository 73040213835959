import { FC, memo, useContext } from 'react';
import {
	makeStyles,
	shorthands,
	tokens,
	TabList,
	Tab,
	SelectTabEvent,
	SelectTabData,
	Subtitle2Stronger,
	Title3,
} from '@fluentui/react-components';
import { bundleIcon, AppsListDetailRegular, AppsListDetailFilled } from '@fluentui/react-icons';
import {
	DrawerBody,
	DrawerHeader,
	DrawerHeaderTitle,
	DrawerInline,
} from '@fluentui/react-components/unstable';
import { useNavigate } from 'react-router-dom';
import { AddIcon } from '_components/icons';
import Button from '_components/button';
import { AuthContext, useLogoutApi } from '_hooks/auth';
import { ApiError } from '_types/common';
import { useSnackbar } from '_components/snackbar';

const useStyles = makeStyles({
	drawerInline: {
		height: `100vh`,
		width: `200px`,
		backgroundColor: tokens.colorBrandBackground2,
	},
	drawerTitle: {
		backgroundColor: tokens.colorNeutralForeground2BrandHover,
		...shorthands.padding(tokens.spacingVerticalS),
	},
	drawerBody: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: tokens.colorBrandBackground2,
		...shorthands.padding(tokens.spacingVerticalL, 0),
	},
	tabList: {
		flex: 'auto',
	},
	dialogContent: {
		height: `75vh`,
		display: 'flex',
		flexDirection: 'column',
		minWidth: 'max-content',
	},
	drawerSubTitle: {
		color: tokens.colorNeutralForeground1,
		paddingLeft: tokens.spacingHorizontalMNudge,
	},
	addFormatButton: {
		margin: tokens.spacingHorizontalMNudge,
	},
	logoutButton: {
		background: tokens.colorStatusDangerBackground3,
		margin: tokens.spacingHorizontalMNudge,
	},
});

const ListIcon = bundleIcon(AppsListDetailFilled, AppsListDetailRegular);

export const Tabs = [
	{ label: 'Subjects', url: '/class/subjects', icon: <ListIcon /> },
	{ label: 'Chapters', url: '/class/chapters', icon: <ListIcon /> },
	{ label: 'Question Set', url: '/class/questionSet', icon: <ListIcon /> },
	{ label: 'All Questions', url: '/class/questions', icon: <ListIcon /> },
	{ label: 'Add questions', url: '/class/add/questions', icon: <AddIcon /> },
	{ label: 'Add sub questions', url: '/class/add/sub-questions', icon: <AddIcon /> },
	{ label: 'All Syllabus', url: '/class/syllabus', icon: <ListIcon /> },
	{ label: 'Add syllabus', url: '/class/add/syllabus', icon: <AddIcon /> },
	{ label: 'All PDFs', url: '/class/attachment', icon: <ListIcon /> },
	{ label: 'Add PDF', url: '/class/add/attachment', icon: <AddIcon /> },
];

const MainDrawer: FC = () => {
	const authContext = useContext(AuthContext);
	const { showSnackbar } = useSnackbar();
	const classes = useStyles();
	const navigate = useNavigate();
	const LogoutApi = useLogoutApi();

	const onTabChange = (event: SelectTabEvent, data: SelectTabData) => {
		navigate(data.value as string);
	};

	const onLogout = () => {
		LogoutApi.mutateAsync()
			.then(() => {
				navigate('/login');
				authContext.setAccessToken('');
				showSnackbar({ message: 'Logout Successfull...', intent: 'success' });
			})
			.catch((error: ApiError) => {
				showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
			});
	};

	return (
		<DrawerInline
			open
			separator
			className={classes.drawerInline}
		>
			<DrawerHeader className={classes.drawerTitle}>
				<DrawerHeaderTitle>
					<Title3 style={{ color: tokens.colorBrandBackground2 }}>KC Academy</Title3>
				</DrawerHeaderTitle>
			</DrawerHeader>

			<DrawerBody className={classes.drawerBody}>
				<Button
					appearance='primary'
					className={classes.addFormatButton}
					icon={<ListIcon />}
					onClick={() => window.open('/format')}
				>
					Format / Formulas
				</Button>
				<Subtitle2Stronger className={classes.drawerSubTitle}>Class</Subtitle2Stronger>
				<TabList
					vertical
					appearance='subtle'
					onTabSelect={onTabChange}
					className={classes.tabList}
				>
					{Tabs.map((o) => (
						<Tab
							key={o.url}
							icon={o.icon}
							value={o.url}
						>
							{o.label}
						</Tab>
					))}
				</TabList>
				<Button
					appearance='primary'
					className={classes.logoutButton}
					onClick={onLogout}
				>
					Logout
				</Button>
			</DrawerBody>
		</DrawerInline>
	);
};

export default memo(MainDrawer);
