import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiSuccessResponse } from '_types/common';
import axios from 'axios';

export interface ChapterResponse {
	_id: string;
	name: string;
}

export interface AddChapterRequest {
	name: string;
}
export const useGetChaptersApi = () =>
	useQuery({
		queryKey: ['chapters'],
		queryFn: () =>
			axios<ChapterResponse[]>({
				method: 'GET',
				url: `/api/class/chapters`,
			}),
	});

export const useAddChaptersApi = () =>
	useMutation({
		mutationFn: (info: { request: AddChapterRequest }) =>
			axios<ApiSuccessResponse>({
				method: 'POST',
				url: `/api/class/chapters`,
				data: info.request,
			}),
	});

export const useUpdateChaptersApi = () =>
	useMutation({
		mutationFn: (info: { request: AddChapterRequest; id: string }) =>
			axios<ApiSuccessResponse>({
				method: 'PUT',
				url: `/api/class/chapters/${info.id}`,
				data: info.request,
			}),
	});
