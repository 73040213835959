import {
	Body1Stronger,
	createTableColumn,
	TableCellLayout,
	TableColumnDefinition,
	Title3,
	tokens,
} from '@fluentui/react-components';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	GetSyllabusRequest,
	SyllabusResponse,
	useDeleteSyllabusApi,
	useGetSyllabusApi,
} from '_hooks/class/syllabus';
import Header from '_components/header';
import Button from '_components/button';
import Table from '_components/table';
import HtmlPreview from '_components/htmlPreview';
import { AddIcon, DeleteIcon, EditIcon } from '_components/icons';
import { useSnackbar } from '_components/snackbar';
import { useAlertDialog } from '_components/alertDialog';
import { ApiError } from '_types/common';
import { useCommonStyles } from '_utils/styles';
import SmartSearch, {
	FilterOption,
	searchRequestGenerator,
	Suggestion,
} from '_components/smartSearch';
import { BoardOption, ClassOption } from '_utils/common';

const filterOptions: FilterOption[] = [
	{ label: 'Board', value: 'board', type: 'suggestion' },
	{ label: 'Class name', value: 'className', type: 'suggestion' },
	{ label: 'Chapter', value: 'chapter', type: 'string' },
	{ label: 'Subject', value: 'subject', type: 'string' },
];

const BOARD: Suggestion = {
	key: filterOptions[filterOptions.findIndex((o) => o.value === 'board')].label,
	ref: filterOptions[filterOptions.findIndex((o) => o.value === 'board')].value,
	values: BoardOption.map((s) => {
		return { displayMember: s, valueMember: { board: s } };
	}),
};

const CLASS: Suggestion = {
	key: filterOptions[filterOptions.findIndex((o) => o.value === 'className')].label,
	ref: filterOptions[filterOptions.findIndex((o) => o.value === 'className')].value,
	values: ClassOption.map((s) => {
		return { displayMember: s, valueMember: { className: s } };
	}),
};
const Syllabus = () => {
	const { showSnackbar } = useSnackbar();
	const { showAlertDialog, hideAlertDialog } = useAlertDialog();
	const classes = useCommonStyles();
	const natigate = useNavigate();

	const [searchRequest, setSearchRequest] = useState<GetSyllabusRequest>();

	const GetSyllabusApi = useGetSyllabusApi(searchRequest);
	const DeleteSyllabusApi = useDeleteSyllabusApi();
	const items: SyllabusResponse[] = GetSyllabusApi.data?.data.data ?? [];
	const columns: TableColumnDefinition<SyllabusResponse>[] = [
		createTableColumn({
			columnId: 'content',
			renderHeaderCell: () => {
				return <Body1Stronger>Syllabus</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{<HtmlPreview html={item.content} />}</TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'board',
			renderHeaderCell: () => {
				return <Body1Stronger>Board</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.board} </TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'class',
			renderHeaderCell: () => {
				return <Body1Stronger>Class</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.className} </TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'subject',
			renderHeaderCell: () => {
				return <Body1Stronger>Subject</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.subject} </TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'chapter',
			renderHeaderCell: () => {
				return <Body1Stronger>Chapter</Body1Stronger>;
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.chapters?.join(', ')} </TableCellLayout>;
			},
		}),
		createTableColumn({
			columnId: 'actions',
			renderHeaderCell: () => {
				return <Body1Stronger>Actions</Body1Stronger>;
			},
			renderCell: (item) => {
				return (
					<>
						<Button
							appearance='transparent'
							aria-label='Edit'
							icon={<EditIcon />}
							onClick={() => natigate(`/class/edit/syllabus/${item._id}`)}
						/>
						<Button
							aria-label='Delete'
							appearance='transparent'
							icon={<DeleteIcon color={tokens.colorStatusDangerBackground3} />}
							onClick={() => onDelete(item._id)}
						/>
					</>
				);
			},
		}),
	];

	const columnSizingOptions = {
		content: {
			defaultWidth: 900,
		},
	};
	const onDelete = (id: string) => {
		showAlertDialog({
			title: 'Do you want to delete attchment?',
			buttons: [
				{
					children: 'No',
					onClick: () => {
						hideAlertDialog();
					},
				},
				{
					children: 'Yes',
					appearance: 'primary',
					onClick: () => {
						DeleteSyllabusApi.mutateAsync({ id })
							.then((res) => {
								void GetSyllabusApi.refetch();
								showSnackbar({ message: res.data.message, intent: 'success' });
								hideAlertDialog();
							})
							.catch((error: ApiError) => {
								showSnackbar({ message: error?.response?.data?.message, intent: 'error' });
							});
					},
				},
			],
		});
	};

	return (
		<>
			<Header
				content={
					<Button
						appearance='primary'
						icon={<AddIcon />}
						iconPosition='before'
						onClick={() => natigate('/class/add/syllabus')}
					>
						Add Syllabus
					</Button>
				}
				title={<Title3>Class Syllabus</Title3>}
			/>
			<div className={`${classes.mainContent} ${classes.mainContentGap}`}>
				<SmartSearch
					allowPagination={true}
					isDataLoading={GetSyllabusApi.isFetching}
					keys={filterOptions}
					suggestions={[BOARD, CLASS]}
					totalPages={Number(GetSyllabusApi.data?.data?.totalPages ?? 0)}
					onFilterChange={(appliedFilters, pageRequest) =>
						setSearchRequest(
							searchRequestGenerator(appliedFilters, pageRequest) as GetSyllabusRequest
						)
					}
					onReload={() => GetSyllabusApi.refetch()}
					showingResults={{
						totalElements: Number(GetSyllabusApi.data?.data?.totalElements ?? 0),
						totalFiltered: Number(GetSyllabusApi.data?.data?.filteredElements ?? 0),
						numberOfElements: Number(GetSyllabusApi.data?.data?.size ?? 0),
					}}
				/>

				<Table
					resizableColumns
					columnSizingOptions={columnSizingOptions}
					columns={columns}
					isLoading={GetSyllabusApi.isLoading}
					items={items}
					resizableColumnsOptions={{
						autoFitColumns: true,
					}}
				/>
			</div>
		</>
	);
};

export default memo(Syllabus);
