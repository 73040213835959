import { Body1Stronger, Button, makeStyles, tokens } from '@fluentui/react-components';
import { FC, useMemo } from 'react';

import { ChevronLeftFilled, ChevronRightFilled } from '@fluentui/react-icons';

type Props = {
	onPageChange: (page: number) => void;
	totalPageCount: number;
	siblingCount: number;
	currentPage: number;
	pageSize: number;
	disabled?: boolean;
};

const Pagination: FC<Props> = (props) => {
	const paginationRange = usePagination(props);
	const styles = useStyles();

	const onNext = () => {
		props.onPageChange(props.currentPage + 1);
	};

	const onPrevious = () => {
		props.onPageChange(props.currentPage - 1);
	};

	const lastPage = paginationRange?.[paginationRange?.length - 1];
	return (
		<div className={`${styles.paginationContainer} paginationContainer`}>
			<Button
				className={'paginationItem-previous'}
				aria-disabled={props.currentPage === 1}
				onClick={onPrevious}
				icon={<ChevronLeftFilled />}
				disabled={props.disabled || props.currentPage === 1}
			/>
			{paginationRange?.map((pageNumber) => {
				if (pageNumber === DOTS) {
					return (
						<Body1Stronger
							key={pageNumber}
							className='paginationItemDots'
						>
							&#8230;
						</Body1Stronger>
					);
				}

				return (
					<Button
						key={pageNumber}
						className={'paginationTtemPage'}
						appearance={pageNumber === props.currentPage ? 'primary' : 'outline'}
						onClick={() => props.onPageChange(pageNumber)}
						icon={<Body1Stronger>{pageNumber}</Body1Stronger>}
						disabled={props.disabled}
					/>
				);
			})}
			<Button
				className={'paginationItemNext'}
				icon={<ChevronRightFilled />}
				disabled={props.disabled || props.currentPage === lastPage}
				onClick={onNext}
			/>
		</div>
	);
};

export default Pagination;

export const DOTS = -1;

const range = (start: number, end: number) => {
	const length = end - start + 1;
	return Array.from({ length }, (_, idx) => idx + start);
};

type usePaginationProps = {
	totalPageCount: number;
	pageSize: number;
	siblingCount: number;
	currentPage: number;
};

export const usePagination = ({
	totalPageCount,
	pageSize,
	siblingCount = 1,
	currentPage,
}: usePaginationProps) => {
	const paginationRange = useMemo(() => {
		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount + 5;

		/*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		/*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = range(1, leftItemCount);

			return [...leftRange, DOTS, totalPageCount];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
			return [firstPageIndex, DOTS, ...rightRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalPageCount, pageSize, siblingCount, currentPage]);

	return paginationRange;
};

const useStyles = makeStyles({
	paginationContainer: {
		display: 'flex',
		alignItems: 'center',
		listStyleType: 'none',
		gap: tokens.spacingVerticalSNudge,
	},
});
