/* eslint-disable indent */
/* eslint-disable import/order */
import { FC, memo, ReactNode, useState } from 'react';
import { Attachment, InputProps, Option } from '_types/common';
import {
	Caption1Stronger,
	Combobox,
	Field,
	Input,
	makeStyles,
	Option as Option_,
	TagGroup,
	Tag,
	TagProps,
	InteractionTag,
	InteractionTagPrimary,
	InteractionTagPrimaryProps,
	Menu,
	MenuItem,
	MenuList,
	MenuPopover,
	MenuTrigger,
	useIsOverflowItemVisible,
	useOverflowMenu,
	Overflow,
	OverflowItem,
	InteractionTagSecondary,
} from '@fluentui/react-components';
import Uploader from './uploader';
import Editor from './editor';

type InputFieldValue = string | Option | Option[] | Attachment[];
export type InputFieldProps = InputProps & {
	/**
	 * @default true
	 */
	showLabel?: boolean;
	fieldName: string;
	value: InputFieldValue;
	onChange?: (
		fieldName: string,
		value: any,
		type?: InputFieldProps['fieldType'],
		event?: any
	) => void;
	addons?: ReactNode;
	className?: string;
	styles?: {
		container?: string;
		input?: string;
		field?: string;
	};
};
const useInputStyles = makeStyles({
	container: {
		display: 'flex',
		flexFlow: 'column',
		alignContent: 'flex-end',
	},
	combobox: {
		minWidth: '-webkit-fill-available ',
		'& .fui-Combobox__listbox': {
			zIndex: 10,
		},
	},
});
const InputField: FC<InputFieldProps> = (props) => {
	const classes = useInputStyles();

	const [query, setQuery] = useState<string>('');

	const filteredOption = props.options?.filter(
		(key) => key.label.toLowerCase().indexOf(query.toLowerCase()) > -1
	);
	return (
		<div className={`${props.styles?.container ?? ''} ${classes.container}`}>
			<Field
				{...props.fieldProps}
				className={props.styles?.field}
				label={<Caption1Stronger children={props.fieldProps?.label as ReactNode} />}
			>
				{(props.fieldType === 'multple-select' || props.fieldType === 'select') && (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{props.fieldType === 'multple-select' && (
							<WithOverflow
								options={props?.value as Option[]}
								onRemove={(value) =>
									props.onChange?.(
										props.fieldName,
										(props?.value as Option[]).filter((o) => value !== o.value),
										props.fieldType
									)
								}
							/>
						)}
						<Combobox
							autoComplete='off'
							inlinePopup
							className={`${classes.combobox} ${props.className ?? ''}`}
							multiselect={props.fieldType === 'multple-select'}
							placeholder={props.fieldProps?.placeholder}
							selectedOptions={
								props.fieldType === 'multple-select'
									? (props?.value as Option[])?.map((v) => v.value)
									: [(props?.value as Option)?.value]
							}
							size={props.fieldProps?.size ?? 'small'}
							value={
								props.fieldType === 'multple-select'
									? query
									: ((props?.value as Option)?.label ?? '')
							}
							onChange={(ev) => setQuery(ev.target.value)}
							onBlur={() => setQuery('')}
							onOptionSelect={(e, v) => {
								setQuery('');
								props.onChange?.(
									props.fieldName,
									props.fieldType === 'multple-select'
										? (props.options?.filter?.((o) =>
												v.selectedOptions.includes(o.value)
											) as Option[])
										: (props.options?.find?.((o) =>
												v.selectedOptions.every((s) => s === o.value)
											) as Option),
									props.fieldType
								);
							}}
						>
							{filteredOption && filteredOption?.length === 0 ? (
								<Option_ disabled>Options not available</Option_>
							) : (
								filteredOption?.map((option) => (
									<Option_
										key={option.value}
										value={option.value}
									>
										{option.label}
									</Option_>
								))
							)}
						</Combobox>
					</div>
				)}
				{props.fieldType === 'editor' && (
					<Editor
						placeholder={props.fieldProps?.placeholder}
						value={props.value as string}
						onChange={(v) => {
							props.onChange?.(props.fieldName, v, props.fieldType);
						}}
					/>
				)}
				{props.fieldType === 'upload' && (
					<Uploader
						value={props.value as []}
						onChange={(files) => props.onChange?.(props.fieldName, files, props.fieldType)}
					/>
				)}
				{(props.fieldType === undefined || props.fieldType === 'text') && (
					<Input
						className={props.className}
						placeholder={props.fieldProps?.placeholder}
						size='small'
						onChange={(e, v) => props.onChange?.(props.fieldName, v.value, props.fieldType, e)}
					/>
				)}
			</Field>
			{props.addons}
		</div>
	);
};

export default memo(InputField);

type DefaultItem = InteractionTagPrimaryProps & { value: string };
const defaultOptions = (options: Option[]): DefaultItem[] =>
	options.map((o) => ({
		value: o.value,
		children: <Caption1Stronger>{o.label}</Caption1Stronger>,
	}));

/**
 * A menu item for an overflow menu that only displays when the tab is not visible
 */
const OverflowMenuItem = memo((props: { tag: TagProps; onRemove: (value: string) => void }) => {
	const { tag } = props;
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const isVisible = useIsOverflowItemVisible(tag.value!);
	if (isVisible) {
		return null;
	}

	return (
		<MenuItem key={tag.value}>
			<Tag
				{...tag}
				as='span'
				dismissible
				dismissIcon={{ 'aria-label': 'remove' }}
				onClick={() => props.onRemove(tag.value as string)}
			/>
		</MenuItem>
	);
});

//----- OverflowMenu -----//

/**
 * A menu for viewing tags that have overflowed and are not visible.
 */
const OverflowMenu = memo((props: { options: Option[]; onRemove: (value: string) => void }) => {
	const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>();

	if (!isOverflowing) {
		return null;
	}

	return (
		<InteractionTag>
			<Menu>
				<MenuTrigger disableButtonEnhancement>
					<InteractionTagPrimary
						ref={ref}
						aria-label={`${overflowCount} more tags`}
					>
						{`+${overflowCount}`}
					</InteractionTagPrimary>
				</MenuTrigger>
				<MenuPopover>
					<MenuList>
						{defaultOptions(props.options).map((item) => (
							<OverflowMenuItem
								key={item.value}
								tag={item}
								onRemove={props.onRemove}
							/>
						))}
					</MenuList>
				</MenuPopover>
			</Menu>
		</InteractionTag>
	);
});

//----- Stories -----//

const useStyles = makeStyles({
	container: {
		overflow: 'hidden',
		padding: '5px',
		zIndex: 0, // stop the browser resize handle from piercing the overflow menu
		height: 'fit-content',
		resize: 'horizontal',
	},
	tagGroup: {
		display: 'flex', // TagGroup is inline-flex by default, but we want it to be same width as the container
	},
	interactionTag: {
		height: 'inherit',
	},
});

export const WithOverflow = memo(
	(props: { options: Option[]; onRemove: (value: string) => void }) => {
		const styles = useStyles();

		return (
			<div className={`overflow-menus ${styles.container}`}>
				<Overflow minimumVisible={2}>
					<TagGroup
						className={styles.tagGroup}
						size='small'
						appearance='brand'
					>
						{defaultOptions(props.options).map(({ value, ...rest }) => (
							<OverflowItem
								key={value}
								id={value}
							>
								<InteractionTag
									key={value}
									className={styles.interactionTag}
								>
									<InteractionTagPrimary {...rest} />
									<InteractionTagSecondary
										aria-label='remove'
										onClick={() => props.onRemove(value)}
									/>
								</InteractionTag>
							</OverflowItem>
						))}
						<OverflowMenu
							options={props.options}
							onRemove={props.onRemove}
						/>
					</TagGroup>
				</Overflow>
			</div>
		);
	}
);
